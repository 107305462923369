import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/index.scss';
import './i18n';
import './screens/index.scss';

import AOS from 'aos';
// import * as Sentry from '@sentry/react';
// import ErrorBoundaryPage from '@screens/ErrorBoundaryPage';
import ErrorBoundary from '@screens/ErrorBoundaryPage/ErrorBoundary';
import { store } from './store/store';
import { App } from './App';

AOS.init();

// Sentry.init({
//   dsn: 'https://d79e145b12cc63f35afa028086f5c25e@o1420494.ingest.us.sentry.io/4507564450775040',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.hydrateRoot(
  document.getElementById('app'),
  // <Sentry.ErrorBoundary fallback={<ErrorBoundaryPage />}>
  // <ErrorBoundary>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  // </ErrorBoundary>,
  ,
  // </Sentry.ErrorBoundary>,
);
