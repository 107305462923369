import { memo } from 'react';
import classNames from 'classnames';
import mockTournamentImage from '@assets/images/mock-tournament.jpg';

import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './TournamentCardImageBlock.module.scss';

type TournamentCardImageBlockSize = 'small' | 'medium'

interface PartOfTournamentType {
  image: null | string;
  category: string;
  name: string;
}

interface TournamentCardImageBlockProps {
  tournament: PartOfTournamentType;
  size?: TournamentCardImageBlockSize;
  className?: string;
}

export const TournamentCardImageBlock = memo((props: TournamentCardImageBlockProps) => {
  const { tournament, size = 'medium', className } = props;

  return (
    <div className={classNames(cls.TournamentCardImageBlock, className, cls[size])}>
      <img
        className={cls.image}
        src={tournament.image ? BACKEND_BASE_URL + tournament.image : mockTournamentImage}
        alt=""
      />
      <div className={cls.category}>
        {tournament.category}
      </div>
      <h3 className={cls.name}>
        {tournament.name}
      </h3>
    </div>
  );
});
