import { memo } from 'react';
import classNames from 'classnames';
import AtpLogoImage from '@assets/images/atp-logo.png';
import cls from './TournamentAds.module.scss';
import { Button } from '../../../../shared/Button/Button';

interface TournamentAdsProps {
  className?: string
}

export const TournamentAds = memo((props: TournamentAdsProps) => {
  const { className } = props;
  return (
    <div className={classNames(cls.TournamentAds, className)}>
      <img
        className={cls.logo}
        src={AtpLogoImage}
        alt="atp"
      />
      <h2 className={cls.title}>More Rewards!</h2>
      <Button className={cls.button} theme="clear">
        Play now
      </Button>
    </div>
  );
});
