import { ReactNode } from 'react';
import classNames from 'classnames';
import AuthService from '@services/AuthService';
import { useSelector } from 'react-redux';
import cls from './Container.module.scss';

interface ContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
  className?: string;
}

export const Container = (props: ContainerProps) => {
  const { children, fullWidth, className } = props;
  const user = useSelector((state) => state.auth?.user);

  return (
    <div className={classNames(cls.Container, user && cls.withSidebar, fullWidth && cls.fullWidth, className)}>
      {children}
    </div>
  );
};
