import { memo } from 'react';
import classNames from 'classnames';
import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './TournamentLiveReplacementBlock.module.scss';

const mockCards = [
  '/media/nft_image_card/image_jEsbGDU.jpg',
  '/media/nft_image_card/image_VmDx8yh.jpg',
  '/media/nft_image_card/image_6fZueiC.jpg',
  '/media/nft_image_card/image_Ga697YI.jpg',
  '/media/nft_image_card/image_lDWbfVp.jpg',
];

interface TournamentLiveReplacementBlockProps {
  className?: string;
}

export const TournamentLiveReplacementBlock = memo((props: TournamentLiveReplacementBlockProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.TournamentLiveReplacementBlock, className)}>
      <ul className={cls.list}>
        {mockCards.map((card) => (
          <li>
            <img
              className={cls.image}
              src={BACKEND_BASE_URL + card}
            />
          </li>
        ))}
      </ul>
    </div>
  );
});
