import { memo, useCallback } from 'react';
import { To, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import LeftArrowIcon from '@assets/icons/left-arrow.svg';
import cls from './BackButton.module.scss';

interface BackButtonProps {
  text?: string;
  to?: string | -1;
  className?: string;
}

export const BackButton = memo((props: BackButtonProps) => {
  const { text = 'Back', to = -1, className } = props;
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(to as To);
  }, [navigate, to]);

  return (
    <button
      className={classNames(cls.BackButton, className)}
    >
      <div className={cls.arrowBlock} onClick={handleBack}>
        <LeftArrowIcon />
      </div>
      <span className={cls.text}>{text}</span>
    </button>
  );
});
