import { memo } from 'react';
import classNames from 'classnames';
import { Page } from '../../../shared/Page/Page';
import cls from './Referal.module.scss';
import { ReferalShareBlock } from '../../../ui/referal/ReferalShareBlock/ui/ReferalShareBlock';
import { ReferalUsers } from '../../../ui/referal/ReferalUsers';
import { Container } from '../../../layout/Container';

interface ReferalProps {
  className?: string;
}

export const Referal = memo((props: ReferalProps) => {
  const { className } = props;

  return (
    <Page
      className={classNames(cls.Referal, className)}
      containerClassName={cls.referalContainer}
      fullWidth
    >
      <ReferalShareBlock className={cls.shareBlock} />
      <Container>
        <ReferalUsers />
      </Container>
    </Page>
  );
});
