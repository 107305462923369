import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Container } from '../../layout/Container';
import cls from './Page.module.scss';

interface PageProps {
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  containerClassName?: string;
}

export const Page: FC<PageProps> = (props) => {
  const {
    children,
    fullWidth = false,
    className,
    containerClassName,
  } = props;

  return (
    <main className={classNames(cls.Page, className)}>
      <Container
        className={classNames(cls.pageContainer, containerClassName)}
        fullWidth={fullWidth}
      >
        {children}
      </Container>
    </main>
  );
};
