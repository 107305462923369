import { memo } from 'react';
import classNames from 'classnames';
import { useFetchTournamentsQuery } from '@services/tournaments.service';
import { Link } from 'react-router-dom';
import cls from './TournamentsList.module.scss';
import { TournamentCard } from '../../TournamentCard';

interface TournamentsListProps {
  className?: string;
}

export const TournamentsList = memo((props: TournamentsListProps) => {
  const { className } = props;
  const { data } = useFetchTournamentsQuery(1);

  return (
    <ul className={classNames(cls.TournamentsList, className)}>
      {data && data.map((item) => (
        <li key={item.id} className={cls.item}>
          <TournamentCard tournament={item} />
        </li>
      ))}
    </ul>
  );
});
