export function checkEmail(email: string) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (email.trim().length === 0) {
    return 'E-mail не может быть пустым';
  }
  if (emailPattern.test(email)) {
    return null;
  }
  return 'E-mail введен некоректно';
}

export function checkPassword(password: string) {
  if (password.length > 0) {
    return null;
  }
  return 'Пароль не может быть пустым';
}
