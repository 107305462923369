import { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import cls from './SidebarItem.module.scss';

import type { SidebarItemType } from '../../model/sidebar';

interface SidebarItemProps {
  item: SidebarItemType;
  className?: string;
}

export const SidebarItem = memo((props: SidebarItemProps) => {
  const { item, className } = props;
  const { pathname } = useLocation();

  const isActiveItem = useMemo(() => (
    (item.to === '/' && pathname === '/')
    || item.to.split('/')[1] === pathname.split('/')[1]
  ), [pathname, item]);

  return (
    <Link
      className={classNames(cls.SidebarItem, isActiveItem && cls.active, className)}
      to={item.to}
    >
      <item.Icon className={cls.icon} />
      <h3 className={cls.name}>
        {item.name}
      </h3>
    </Link>
  );
});
