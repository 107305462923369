import React, {
  createContext, ReactNode, useContext, useEffect, useState,
} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useQueryClient } from '@tanstack/react-query';
import { BACKEND_WS_URL } from '../constants/constants';
import AuthService from '../services/AuthService';
import { AuthContext } from './AuthContext';

const DefaultProps = {
  notification_types: [],
  connectionStatus: 'Uninstantiated',
  sendJsonMessage: () => null,
  setNotificationTypes: () => null,
};

export interface NotificationProps {
  notification_types: Array<NotificationExample>;
  connectionStatus: string;
  sendJsonMessage: any;
  setNotificationTypes: any;
}

export interface NotificationExample {
  notification_type: string;
  notification_types: string;
  type: string;
  message: {
    avatar: string;
    friendly_match_id: number;
    nickname: string;
    sender_id: number;
    status: string;
    type: string;
    message?: string;
  };
}

export const NotificationContext = createContext<NotificationProps>(DefaultProps);

export const NotificationContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  if (typeof window !== 'undefined') {
    const user = AuthService.getCurrentUser();
    const [notification_types, setNotificationTypes] = useState<Array<NotificationExample>>([]);

    // const {
    //   readyState,
    //   sendJsonMessage,
    //   getWebSocket: getNotificationWebSocket,
    // } = useWebSocket(`${BACKEND_WS_URL}/notifications/`, {
    //   queryParams: {
    //     token: user ? user!.token : '',
    //   },
    //   shouldReconnect: (closeEvent) => !!user,
    //   onOpen: () => console.log('Connected to Notifications!'),
    //   onClose: () => console.log('Disconnected from Notifications!'),
    //   onMessage: (e: MessageEvent) => {
    //     const data = JSON.parse(e.data);
    //     switch (data.type) {
    //     case 'notifications':
    //       setNotificationTypes((prevNotificationTypes) => [...prevNotificationTypes, data]);
    //       break;
    //     default:
    //       console.error('Unknown message type!');
    //       break;
    //     }
    //   },
    // });

    // const {
    //   readyState: onlineStatusReadyState,
    //   getWebSocket: getOnlineStatusWebSocket,
    // } = useWebSocket(`${BACKEND_WS_URL}/online/?token=${user?.token}`, {
    //   shouldReconnect: (closeEvent) => !!user,
    //   onOpen: () => console.log('Connected to OnlineSocket'),
    //   onClose: () => console.log('Disconnected from OnlineSocket'),
    // });

    // useEffect(() => {
    //   if (!user) {
    //     getNotificationWebSocket()?.close();
    //     getOnlineStatusWebSocket()?.close();
    //     setNotificationTypes([]);
    //   }
    // }, [user, onlineStatusReadyState]);

    // const connectionStatus = {
    //   [ReadyState.CONNECTING]: 'Connecting',
    //   [ReadyState.OPEN]: 'Open',
    //   [ReadyState.CLOSING]: 'Closing',
    //   [ReadyState.CLOSED]: 'Closed',
    //   [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyState];

    return (
      <NotificationContext.Provider value={{
        notification_types: [],
        connectionStatus: '',
        sendJsonMessage: () => {},
        setNotificationTypes: () => {},
      }}
      >
        {children}
      </NotificationContext.Provider>
    );
  }
};

export const useNotificationContext = () => React.useContext(NotificationContext);
