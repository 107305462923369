import { memo } from 'react';
import classNames from 'classnames';
import { Route, Routes } from 'react-router-dom';
import { Page } from '../../../shared/Page/Page';
import { RatingNavigation } from '../../../ui/RatingNavigation';
import { RatingUsersList } from '../../../ui/RatingUsersList';
import cls from './Rating.module.scss';
import { RatingPlayersCardsList } from '../../../ui/RatingPlayersCardsList';

interface RatingProps {
  className?: string;
}

export const Rating = memo((props: RatingProps) => {
  const { className } = props;

  return (
    <Page
      className={classNames(cls.Rating, className)}
      containerClassName={cls.ratingContainer}
    >
      <RatingNavigation className={cls.nav} />
      <Routes>
        <Route
          path="users"
          element={<RatingUsersList />}
        />
        <Route
          path="cards"
          element={<RatingPlayersCardsList />}
        />
      </Routes>
    </Page>
  );
});
