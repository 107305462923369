import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';

export type Rarity = 'ADVANCED' | 'RARE' | 'UNIQUE' | 'LEGENDARY' | 'COMMON'

interface PlayerCard {
  id: number;
  price?: number;
  circulation_number?: number;
  image_card: string;
}

interface FetchPlayersCardsResponse {
  next: string;
  pages: number;
  previous: string | null;
  results: {
    id: number;
    price: number;
    nft: {
      circulation_number: number;
      image_card: string;
    }
  }[]
}

interface FetchPlayersCardsResponseNormalize {
  next: string;
  pages: number;
  previous: string | null;
  results: PlayerCard[]
}

export interface CardItem {
  id: number;
  image: null | string;
  name?: string;
  price?: number;
}

interface FetchPlayersItemsResponse {
  pages: 1,
  next: null,
  previous: null,
  results: CardItem[]
}

export interface CrewCardType {

}

type FetchMyClubPlayersItemsResultsType = {
  item: CardItem;
} | {
  crew_card: CrewCard
}

interface FetchMyClubPlayersItemsResponse {
  pages: 1,
  next: null,
  previous: null,
  results: FetchMyClubPlayersItemsResultsType[]
}

interface AwardTranslation {
  category: string;
  description: string;
  name: string;
}

export interface Translations {
  en: AwardTranslation;
  ru: AwardTranslation
}

interface Award {
  category: string;
  description: string;
  id: number;
  image: string;
  name: string;
  serial_number: null | number;
  translations: Translations
}

type FetchAwardsResponse = Award[];

export const cardsAPI = createApi({
  reducerPath: 'cardsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchMarketCards: builder.query<FetchPlayersCardsResponseNormalize, {[key: string]: string}>({
      transformResponse: (response: FetchPlayersCardsResponse) => ({
        ...response,
        results: response.results.map((item) => ({ ...item, ...item.nft, ...item.nft.nft })),
      }),
      query: (filtersQuery) => ({
        url: '/market/nft_product_auction/',
        params: filtersQuery,
      }),
    }),
    fetchMarketCardsItems: builder.query<FetchPlayersItemsResponse, void>({
      query: () => ({
        url: '/market/products/items_sell/',
      }),
    }),
    fetchMyClubCardsItems: builder.query<FetchPlayersItemsResponse, void>({
      query: () => ({
        url: '/account/user_collection/?page=120',
      }),
    }),
    fetchMyClubCards: builder.query<FetchPlayersCardsResponseNormalize, void>({
      query: () => ({
        url: '/account/user_nftcards/',
        method: 'GET',
      }),
    }),
    fetchAwards: builder.query<FetchAwardsResponse, void>({
      query: () => ({
        url: '/items/achievements/',
      }),
    }),
  }),
});

export const {
  useFetchMarketCardsQuery,
  useFetchMarketCardsItemsQuery,
  useFetchMyClubCardsItemsQuery,
  useFetchMyClubCardsQuery,
  useFetchAwardsQuery,
} = cardsAPI;
