import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';

export interface RatingUser {
  avatar: null | string;
  experience: number;
  level: number;
  id: number | string;
  nickname: string;
}

export interface RatingCard {
  image: null | string;
  name: string;
  nickname: string;
  rarity: string;
  rating: number;
  user: number;
  image_card: string;
}

export interface RatingCardsResponse {
  count: number;
  next: string;
  previous: number | null;
  results: RatingCard[];
}

export const ratingApi = createApi({
  reducerPath: 'ratingAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchUsersRatings: builder.query<RatingUser[], void>({
      query: () => ({
        url: '/account/top/all/',
      }),
    }),
    fetchCardsRatings: builder.query<RatingCard[], void>({
      transformResponse: (response: RatingCardsResponse) => response.results,
      query: () => ({
        url: '/nft/nft_card_top/?ordering=-nft__rating&page=2',
      }),
    }),
  }),
});

export const {
  useFetchUsersRatingsQuery,
  useFetchCardsRatingsQuery,
} = ratingApi;
