import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchProfileQuery } from '@services/profile.service';
import classNames from 'classnames';
import { Page } from '../../../shared/Page/Page';
import { ProfileHeader } from '../../../ui/profile/ProfileHeader';
import { ProfileInfoCard } from '../../../ui/profile/ProfileInfoCard';
import { Container } from '../../../layout/Container';
import { ProfileContent } from '../../../ui/profile/ProfileContent';
import cls from './Profile.module.scss';

interface ProfileProps {
  className?: string;
}

export const Profile = memo((props: ProfileProps) => {
  const { className } = props;
  const { profileId } = useParams<{ profileId: string }>();
  const { data } = useFetchProfileQuery(profileId);

  return (
    <Page
      className={classNames(cls.Profile, className)}
      containerClassName={cls.profileContainer}
      fullWidth
    >
      <ProfileHeader />
      <Container className={cls.contentContainer}>
        <ProfileInfoCard
          className={cls.profileInfo}
          user={data}
        />
        <ProfileContent
          user={data}
        />
      </Container>
    </Page>
  );
});
