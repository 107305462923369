import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '@constants/constants';
import AuthService from './AuthService';

export interface Profile {
  id: number,
  nickname: string,
  username: string,
  first_name: string,
  last_name: string,
  middle_name: null | string,
  avatar: string,
  followers_count: number,
  followings_count: number,
  is_follow: boolean,
  nickname_counter: number,
  online: boolean,
  is_verified_phone: boolean,
  is_verified_email: boolean,
  match_counter: number,
  victory_counter: number
}

interface Transaction {
  USDT: boolean;
  amount: string;
  content_type: number;
  created_time: Date;
  id: number;
  image: string;
  name: string;
  object_id: number;
  operation: string;
  user: number
}

interface FetchTransactionsResponse {
  count: number;
  next: string | number;
  previous: string | null;
  results: Transaction[];
}

interface Referal {
  id: number,
  nickname: string,
  income: string
}

export const profileAPI = createApi({
  reducerPath: 'profileAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchProfile: builder.query<Profile, string>({
      query: (id) => ({
        url: `/account/profile/${id}/`,
      }),
    }),
    fetchTeams: builder.query<Team[], string | number>({
      query: (userId) => ({
        url: `/nft/teams/${userId}/`,
      }),
      providesTags: ['teams'],
    }),
    deleteTeam: builder.mutation<void, string>({
      query: (teamId) => ({
        url: `/nft/delete_team/${teamId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['teams'],
    }),
    fetchTransactions: builder.query<FetchTransactionsResponse, void>({
      query: () => ({
        url: '/wallet/transactions/',
      }),
    }),
    fetchReferals: builder.query<Referal[], void>({
      query: () => ({
        url: '/account/referrals/',
      }),
    }),
  }),
});

export const {
  useFetchProfileQuery,
  useFetchTeamsQuery,
  useDeleteTeamMutation,
  useFetchTransactionsQuery,
  useFetchReferalsQuery,
} = profileAPI;
