import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { TournamentResultsMatch } from '@services/tournaments.service';
import { Avatar } from '@newComponents/shared/Avatar/Avatar';
import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './TournamentGridMatch.module.scss';
import AuthService from '@services/AuthService';

interface TournamentGridMatchProps {
  match: TournamentResultsMatch;
  className?: string;
}

export const TournamentGridMatch = memo((props: TournamentGridMatchProps) => {
  const { match, className } = props;
  const currentUser = AuthService.getCurrentUser();

  const isCurrentUserMatch = useMemo(() => (
    currentUser.id === match.player1?.user.id ||
    currentUser.id === match.player2?.user.id
  ))

  const isCurrentUserWinner = useMemo(() => (
    currentUser.id === match.winner?.user.id
  ), [currentUser, match]);

  return (
    <div className={classNames(cls.TournamentGridMatch, className)}>
      {match.player1 && match.player2 ? (
        <>
          <div className={classNames(cls.user, isCurrentUserWinner && match.player1?.user.id === currentUser.id && cls.winner, isCurrentUserMatch && cls.userMatch)}>
            <Avatar
              className={cls.avatar}
              src={match.player1?.user.avatar && BACKEND_BASE_URL + match.player1?.user.avatar}
            />
            <span className={cls.name}>
              {match.player1?.user.nickname}
            </span>
          </div>
          <div className={classNames(cls.user, isCurrentUserWinner && match.player2?.user.id === currentUser.id && cls.winner, isCurrentUserMatch && cls.userMatch)}>
            <Avatar
              className={cls.avatar}
              src={match.player2?.user.avatar && BACKEND_BASE_URL + match.player2?.user.avatar}
            />
            <span className={cls.name}>
              {match.player2?.user.nickname}
            </span>
          </div>
        </>
      ) : (
        <div className={cls.user}>
          <span>-</span>
        </div>
      )}
    </div>
  );
});
