import { memo } from 'react';
import classNames from 'classnames';
import { useFetchReferalsQuery } from '@services/profile.service';
import cls from './ReferalUsers.module.scss';

interface ReferalUsersProps {
  className?: string;
}

export const ReferalUsers = memo((props: ReferalUsersProps) => {
  const { className } = props;
  const { data } = useFetchReferalsQuery();

  return (
    <ul className={classNames(cls.ReferalUsers)}>
      <div className={classNames(cls.columns, cls.headColumns)}>
        <div className={classNames(cls.column, cls.headColumn)}>Username</div>
        <div className={classNames(cls.column, cls.headColumn)}>Earned</div>
      </div>

      {data && data.map((user) => (
        <div key={user.id} className={cls.columns}>
          <div className={classNames(cls.column)}>{user.nickname}</div>
          <div className={classNames(cls.column)}>
            <span className={cls.income}>
              +
              {user.income}
              $
            </span>
          </div>
        </div>
      ))}
    </ul>
  );
});
