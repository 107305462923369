import { memo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getFormatNumber } from '@utils/getFormatNumber';
import cls from './Balance.module.scss';
import { Button } from '../../../shared/Button/Button';

interface BalanceInfoProps {
  className?: string;
}

export const Balance = memo((props: BalanceInfoProps) => {
  const { className } = props;
  const balance = useSelector((state) => state.auth.user.balance);

  return (
    <div className={classNames(cls.Balance, className)}>
      <div className={cls.count}>
        $
        {getFormatNumber(balance)}
      </div>
      <Button
        className={cls.button}
        theme="contained-green"
      >
        Cash out
      </Button>
    </div>
  );
});
