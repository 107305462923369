import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ContactsPage.scss';

const ContactsPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <main className="pb-20 pt-20 flex flex-col items-center">
      <h2 className="rules__title">
        {t('contacts.title')}
      </h2>
      <article className="rules__article">
        <ul>
          <li className="contacts__item">
            {' '}
            <h3 className="contacts__email-title">
              {t('contacts.for_commercial_questions')}
              :
            </h3>
            <p className="contacts__data">nft.tennis@gmail.com</p>
          </li>
          <li>
            <h3 className="contacts__email-title">
              {t('contacts.ads')}
              :
            </h3>
            <p className="contacts__data">nft.tennis.seo@gmail.com</p>
          </li>
        </ul>

      </article>
    </main>
  );
};

export default ContactsPage;
