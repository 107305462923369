import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import cls from './PageTitle.module.scss';

interface PageTitleProps {
  className?: string;
}

function getCurrentPageTitle(firstRoutePart: string) {
  console.log(firstRoutePart);
  switch (firstRoutePart) {
  case 'market':
    return 'Market';
  case 'tournaments':
    return 'Tournaments';
  case 'my-club':
    return 'My club';
  case 'rating':
    return 'Rating';
  case 'profile':
    return 'Profile';
  default:
    return 'Page';
  }
}

export const PageTitle = memo((props: PageTitleProps) => {
  const { className } = props;
  const location = useLocation();
  const [currentPageTitle, setCurrentPageTitle] = useState();

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrentPageTitle('Main');
      return;
    }
    const firstRoutePart = location.pathname.split('/').filter(Boolean)[0];
    const newTitle = getCurrentPageTitle(firstRoutePart);
    setCurrentPageTitle(newTitle);
  }, [location]);

  return (
    <h2 className={classNames(cls.PageTitle, className)}>
      {currentPageTitle}
    </h2>
  );
});
