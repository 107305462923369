import { memo, useEffect, useState } from 'react';
import type { MysteryCaseDrop, MysteryCaseInfo } from '@services/mystery.service';
import _ from 'lodash';
import classNames from 'classnames';
import caseDividerImage from '@img/case-divider.png';
import cls from './MysteryCaseRoulette.module.scss';
import { MysteryCaseRouletteItem } from '../../MysteryCaseRouletteItem';

interface MysteryCaseRouletteProps {
  drops: MysteryCaseDrop[];
  isOpened: boolean;
  onDrop: () => void;
  className?: string;
}

export const MysteryCaseRoulette = memo((props: MysteryCaseRouletteProps) => {
  const {
    drops, isOpened, onDrop, className,
  } = props;
  const [properties, setProperties] = useState<{items: MysteryCaseDrop[], offset: number}>();
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    function getRandomItem(): MysteryCaseDrop {
      const rolledItems = drops.map((item) => (
        { name: item.name || item.name?.ru || '', ...item }
      ));

      return _.sample(rolledItems);
    }

    const itemWidth = 230 + 10;
    const resultIndex = 42;
    setProperties({
      items: [
        ...new Array(resultIndex).fill(null).map(() => getRandomItem()),
        getRandomItem(),
        ...new Array(4).fill(null).map(() => getRandomItem()),
      ],
      offset: itemWidth * (resultIndex + 0.5) - 380,
    });

    setTimeout(() => onDrop(), 5000);
  }, [drops, onDrop]);

  // eslint-disable-next-line no-unsafe-optional-chaining
  useEffect(() => setMargin(-properties?.offset), [properties?.offset]);

  return (
    <div className={classNames(cls.MysteryCaseRoulette, className)}>
      <div className={cls.overlay}>
        <div className={cls.circle} />
      </div>
      <div className={cls.container}>
        <div className={cls.display}>
          <div className={cls.screen} />
          <img className={cls.divider} src={caseDividerImage} alt="case-divider" />
          <div className={cls.roller} style={{ marginLeft: margin }}>
            {/* {properties?.items?.map((item, i) => <div style={{width: 230, height: 230}} />)} */}
            {properties?.items?.map((item, i) => (
              <MysteryCaseRouletteItem key={i} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
