import { memo } from 'react';
import classNames from 'classnames';
import { MysteryCaseDrop } from '@services/mystery.service';
import { getItemShadowColor } from '@utils/mysteryCases';
import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './MysteryCaseRouletteItem.module.scss';

interface MysteryCaseRouletteItemProps {
  item: MysteryCaseDrop;
  className?: string;
}

export const MysteryCaseRouletteItem = memo((props: MysteryCaseRouletteItemProps) => {
  const { item, className } = props;

  if (item?.type === 'item') {
    return (
      <div className={classNames(cls.item)}>
        <div className={classNames(cls.item, cls.purple)}>
          {/* <SkillBlock name={item?.name?.ru} /> */}
        </div>
      </div>
    );
  }

  if (item.rarity) {
    return (
      <div className={classNames(cls.item, props.opened && props.droppedItem ? props.droppedItem?.price === item?.price ? cls.dropped : cls.noDrop : '', getItemShadowColor(item.rarity))}>
        <div className={classNames(cls.item, cls[getItemShadowColor(item.rarity || item.nft?.rarity)])}>
          {/* <CharacteristicBlock image={getRandomPlayerImage(item.rarity)} rarity={item.rarity} name={item.name} /> */}
        </div>
      </div>
    );
  }

  if (item.name) {
    return (
      <div className={classNames(cls.item, props.opened && props.droppedItem ? props.droppedItem?.price === item?.price ? cls.dropped : cls.noDrop : '')}>
        <div className={cls.item}>
          <div className={classNames(cls.img)} />
          {/* {item.name} */}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(cls.MysteryCaseRouletteItem, className)} />
  );
});
