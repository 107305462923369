import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { type TournamentsArticleType, useFetchTournamentsNewsQuery } from '@services/news.service';
import getFormatDateTime from '@utils/formatDateTime';
import { Button } from '@newComponents/shared/Button/Button';
import cls from './TournamentsNewsList.module.scss';

interface TournamentsNewsListProps {
  news: TournamentsArticleType[];
  onChangeArticle: (article: TournamentsArticleType) => void;
  className?: string;
}

export const TournamentsNewsList = memo((props: TournamentsNewsListProps) => {
  const { news, onChangeArticle, className } = props;

  return (
    <ul className={classNames(cls.TournamentsNewsList, className)}>
      {news.map((newsItem) => (
        <li className={cls.item} onClick={() => onChangeArticle(newsItem)}>
          <h3 className={cls.title}>
            {newsItem.title}
          </h3>
          <p className={cls.description}>
            {newsItem.text.slice(0, 200)}
            ...
          </p>
          <span className={cls.date}>
            {getFormatDateTime(newsItem.created_at)}
          </span>
          <Button className={cls.button} theme="contained-white">
            Read Article
          </Button>
        </li>
      ))}
    </ul>
  );
});
