import classNames from 'classnames';
import { FormEvent, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import WechatIcon from '@assets/icons/wechat.svg';
import GoogleIcon from '@assets/icons/google.svg';
import FacebookIcon from '@assets/icons/facebook.svg';
import MetamaskIcon from '@assets/icons/metamask.svg';
import { useDispatch, useSelector } from 'react-redux';
import { loginByEmail } from '@store/services/loginByEmail';
import { authActions, type AuthFields } from '@store/slices/auth';
import { checkEmail, checkPassword } from '@utils/validate';
import { Button } from '../../../shared/Button/Button';
import { Input } from '../../../shared/Input/Input';
import { Modal } from '../../../shared/Modal/Modal';
import cls from './LoginModal.module.scss';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

function validate(username: string, password: string) {
  const errors: AuthFields = {};
  const usernameError = checkEmail(username);
  const passwordError = checkPassword(password);
  if (usernameError) {
    errors.username = usernameError;
  }
  if (passwordError) {
    errors.password = passwordError;
  }
  return errors;
}

export const LoginModal = memo((props: LoginModalProps) => {
  const { isOpen, onClose, className } = props;
  const dispatch = useDispatch();
  const username = useSelector((state) => state.auth.username);
  const password = useSelector((state) => state.auth.password);
  const usernameError = useSelector((state) => state.auth.validateErrors.username);
  const passwordError = useSelector((state) => state.auth.validateErrors.password);
  const authError = useSelector((state) => state.auth.authError);
  const authLoading = useSelector((state) => state.auth.isLoading);

  const handleChangeUsername = useCallback((value: string) => {
    dispatch(authActions.setUsername(value));
  }, [dispatch]);

  const handleChangePassword = useCallback((value: string) => {
    dispatch(authActions.setPassword(value));
  }, [dispatch]);

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    const errors = validate(username, password);
    console.log(errors);
    if (Object.keys(errors).length > 0) {
      console.log('length');
      dispatch(authActions.setValidateErrors(errors));
      return;
    }
    dispatch(loginByEmail());
  }, [username, password, dispatch]);

  return (
    <Modal className={classNames(cls.LoginModal, className)} isOpen={isOpen} onClose={onClose}>
      <form className={cls.form} onSubmit={handleSubmit}>
        <h2 className={cls.title}>Sign in</h2>
        <Input
          className={cls.inputBlock}
          inputClassName={cls.input}
          placeholder="e-mail"
          value={username}
          error={usernameError}
          onChange={handleChangeUsername}
        />
        <Input
          className={cls.inputBlock}
          inputClassName={cls.input}
          type="password"
          placeholder="password"
          value={password}
          error={passwordError || authError}
          onChange={handleChangePassword}
        />
        <Button
          type="submit"
          className={cls.submitButton}
          theme="contained-green"
          disabled={authLoading}
        >
          Sign in
        </Button>
        <Link className={cls.forgotLink} to="/send_code">
          Forgot password?
        </Link>
      </form>
      <div className={cls.lineBlock}>
        <div className={cls.line} />
        <span>or</span>
        <div className={cls.line} />
      </div>
      <ul className={cls.oauthList}>
        <li className={classNames(cls.oauthItem)}>
          <Link className={classNames(cls.oauthLink, cls.wechatLink)} to="#">
            <WechatIcon />
          </Link>
        </li>
        <li className={classNames(cls.oauthItem)}>
          <Link className={classNames(cls.oauthLink, cls.googleLink)} to="#">
            <GoogleIcon />
          </Link>
        </li>
        <li className={classNames(cls.oauthItem)}>
          <Link className={classNames(cls.oauthLink, cls.facebookLink)} to="#">
            <FacebookIcon />
          </Link>
        </li>
      </ul>
      <Link className={cls.metamaskLink} to="#">
        <MetamaskIcon />
        <span>Войти с помощью MetaMask</span>
      </Link>
    </Modal>
  );
});
