import type { FC } from 'react';
import {
  useRef, useState, useLayoutEffect, memo,
} from 'react';
import preview_advanced from '@img/cards/advanced_preview.png';
import preview_common from '@img/cards/common_preview.png';
import preview_rare from '@img/cards/rare_preview.png';
import preview_legendary from '@img/cards/legendary_preview.png';
import preview_unique from '@img/cards/unique_preview.png';
import traumaIcon from '@img/trauma-icon.png';
import mockCard from '@img/mock-card.png';
import { IoMdMale, IoMdFemale } from 'react-icons/io';

import { useHover } from '@hooks/useHover/useHover';
import LinearProgress from '@components/LinearProgress';
import type { TCharacteristicCard } from './types';
import { AddPlayerCard } from '../AddPlayerCard';
import './CharacteristicCard.scss';

function getRarity(rarity: string) {
  switch (rarity) {
  case 'legendary':
    return ['bg-gradient-to-r from-red-400 to-red-700'];
  case 'rare':
    return ['bg-gradient-to-r from-blue-700 to-blue-400'];
  case 'advanced':
    return ['bg-gradient-to-r from-green to-emerald-600'];
  case 'common':
    return ['bg-gradient-to-r from-neutral-100 to-neutral-600'];
  case 'unique':
    return ['bg-gradient-to-r from-yellow-200 to-yellow-700'];
  }
}

function getSecondRarity(rarity: string) {
  switch (rarity) {
  case 'legendary':
    return ['bg-gradient-to-r from-red-500 to-red-800'];
  case 'rare':
    return ['bg-gradient-to-r from-blue-800 to-blue-500'];
  case 'advanced':
    return ['bg-gradient-to-r from-green to-emerald-900'];
  case 'common':
    return ['bg-gradient-to-r from-neutral-100 to-neutral-700'];
  case 'unique':
    return ['bg-gradient-to-r from-yellow-200 to-yellow-800'];
  }
}

const previewImages = {
  preview_unique,
  preview_legendary,
  preview_rare,
  preview_advanced,
  preview_common,
};

const CharacteristicCard: FC<TCharacteristicCard> = memo((props) => {
  const {
    card, isLearn = false, isNotFullWidth, style, styleHeadBlock, noAnimate,
  } = props;

  const ref = useRef(null);
  const [fontSize, setFontSize] = useState<number>(13);
  const [isHovered, { onMouseEnter, onMouseLeave }] = useHover();

  useLayoutEffect(() => {
    if (ref.current) {
      const { offsetWidth } = ref.current;
      setFontSize(offsetWidth / 10);
    }
  }, [ref.current]);

  if (!card) return <AddPlayerCard />;
  const rarity = card?.nft?.rarity?.toLowerCase() as | 'legendary'
    | 'rare'
    | 'advanced'
    | 'common'
    | 'unique';
  const [cardBG, gradient] = getRarity(rarity);
  const [secondBG] = getSecondRarity(rarity);
  const imagePreview = () => previewImages[`preview_${rarity}`];
  console.log(cardBG);

  const chrColor = rarity === 'legendary' || rarity === 'rare' || rarity === 'advanced' ? 'text-white' : 'text-black';

  if (isHovered) {
    return (
      <div
        className={`mystery-container transition-all shadow-cyan-500/50 w-full aspect-[1/1.6] relative text-white ${
          card?.trauma || isLearn ? 'card-trauma' : ''
        }`}
        style={{ fontSize, ...styleHeadBlock }}
        ref={ref}
      >
        <div
          className={`${isNotFullWidth ? '' : 'w-full'} h-full bg-cover rounded-md px-[.5em] ${
            card?.trauma || isLearn ? 'grayscale' : ''
          } absolute overflow-y-auto ${cardBG}`}
          style={{
            backgroundImage: `url(${cardBG})`, ...styleHeadBlock, ...style, boxShadow: '4px 5px 27px 38px rgba(34, 60, 80, 0.51)',
          }}
          onMouseLeave={onMouseLeave}
        >
          <div className="flex justify-between items-center mb-3 pt-1">
            <div className="rounded-[4px] text-orange-400 px-2 py-[.2em] bg-black border-solid border-[1px] border-orange-400 text-[.6em]">
              #
              {card.circulation_number}
            </div>
            <div
              className="rounded-md px-2 py-[.2em] text-[.5em] leading-tight bg-gradient-to-r from-red-400 to-red-700"
            >
              <div className="flex items-center justify-between">
                <svg width="20" height="20" viewBox="0 0 7.5 7.5" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <desc>
                    Created with Pixso.
                  </desc>
                  <defs />
                  <path id="stars" d="M2.25 6L3.75 4.85L5.25 6L4.68 4.14L6.18 3.07L4.35 3.07L3.75 1.12L3.14 3.07L1.31 3.07L2.81 4.14L2.25 6ZM3.75 7.5C3.23 7.5 2.74 7.4 2.28 7.2C1.83 7 1.43 6.74 1.09 6.4C0.75 6.06 0.49 5.66 0.29 5.21C0.09 4.75 0 4.26 0 3.75C0 3.23 0.09 2.74 0.29 2.28C0.49 1.83 0.75 1.43 1.09 1.09C1.43 0.75 1.83 0.49 2.28 0.29C2.74 0.09 3.23 0 3.75 0C4.26 0 4.75 0.09 5.21 0.29C5.66 0.49 6.06 0.75 6.4 1.09C6.74 1.43 7 1.83 7.2 2.28C7.4 2.74 7.5 3.23 7.5 3.75C7.5 4.26 7.4 4.75 7.2 5.21C7 5.66 6.74 6.06 6.4 6.4C6.06 6.74 5.66 7 5.21 7.2C4.75 7.4 4.26 7.5 3.75 7.5ZM3.75 6.75C4.58 6.75 5.29 6.45 5.87 5.87C6.46 5.29 6.75 4.58 6.75 3.75C6.75 2.91 6.46 2.2 5.87 1.62C5.29 1.04 4.58 0.75 3.75 0.75C2.91 0.75 2.2 1.04 1.62 1.62C1.03 2.2 0.75 2.91 0.75 3.75C0.75 4.58 1.03 5.29 1.62 5.87C2.2 6.45 2.91 6.75 3.75 6.75Z" fill="#FFFFFF" fillOpacity="1.000000" fillRule="nonzero" />
                </svg>
                <div className="w-1/2">
                  {/* <FirstIcon /> */}
                </div>
                <p>{card.nft?.rating}</p>
              </div>
            </div>
          </div>
          <ul>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>FOREHAND</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_forehand} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_forehand}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>BACKHAND</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_backhand} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_backhand}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>SERVE</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_serve} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_serve}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>RECEPTION</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_reception} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_reception}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>VOLLEY</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_volley} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_volley}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>STAMINA</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_stamina} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_stamina}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>TALENT</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_talent} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_talent}
                  /100
                </span>
              </div>
            </li>
            <li className="flex justify-between mb-1">
              <h4 className={`text-[65%] font-black w-16 min-w-[50%] mr-2 ${chrColor}`}>FOREHAND</h4>
              <div className="w-full flex-col flex items-end">
                <LinearProgress color={chrColor} progress={card?.chr_forehand} />
                <span className={`text-[50%] ${chrColor}`}>
                  {card?.chr_forehand}
                  /100
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`mystery-container w-full aspect-[1/1.6] relative text-white ${
        card?.trauma || isLearn ? 'card-trauma' : ''
      }`}
      style={{ fontSize, ...styleHeadBlock }}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {(card.trauma || isLearn || card.on_team) && (
        <div
          className={`absolute -top-[1.5em] z-[1] text-[.5em] transform -translate-x-1/2 left-1/2 text-black border-[1px] border-black border-solid rounded-md px-[1em] w-fit ${
            card.trauma || isLearn
              ? 'bg-rose-400'
              : card?.on_team
                ? 'bg-green'
                : ''
          } `}
        >

          {(card.trauma || isLearn) ? (
            <div className="flex">
              <img
                className="w-[2em] h-[2em] inline"
                src={traumaIcon}
                alt="injury"
                loading="lazy"

              />
            </div>
          ) : card.on_team ? card?.on_team && (
            <div className="flex items-center justify-center text-[1.5em]">
              Team
            </div>
          ) : ''}
        </div>
      )}
      <div
        className={`${isNotFullWidth ? '' : 'w-full'} h-full bg-cover rounded-md px-[.5em] ${
          card?.trauma || isLearn ? 'grayscale' : ''
        } ${cardBG}`}
        style={{ backgroundImage: `url(${cardBG})`, ...styleHeadBlock, ...style }}
      >
        <div className="relative grid grid-rows-[10%_55%_35%] w-full h-full pt-1 pb-[.5em]">
          <div className="flex flex-row items-center justify-between">
            <div className="rounded-[4px] text-orange-400 px-2 py-[.2em] bg-black border-solid border-[1px] border-orange-400 text-[.6em]">
              #
              {card.circulation_number}
            </div>
            <div
              className="rounded-md px-2 py-[.2em] text-[.5em] leading-tight leading-tight bg-gradient-to-r from-red-400 to-red-700"
            >
              <div className="flex items-center justify-between">

                <svg width="20" height="20" viewBox="0 0 7.5 7.5" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <desc>
                    Created with Pixso.
                  </desc>
                  <defs />
                  <path id="stars" d="M2.25 6L3.75 4.85L5.25 6L4.68 4.14L6.18 3.07L4.35 3.07L3.75 1.12L3.14 3.07L1.31 3.07L2.81 4.14L2.25 6ZM3.75 7.5C3.23 7.5 2.74 7.4 2.28 7.2C1.83 7 1.43 6.74 1.09 6.4C0.75 6.06 0.49 5.66 0.29 5.21C0.09 4.75 0 4.26 0 3.75C0 3.23 0.09 2.74 0.29 2.28C0.49 1.83 0.75 1.43 1.09 1.09C1.43 0.75 1.83 0.49 2.28 0.29C2.74 0.09 3.23 0 3.75 0C4.26 0 4.75 0.09 5.21 0.29C5.66 0.49 6.06 0.75 6.4 1.09C6.74 1.43 7 1.83 7.2 2.28C7.4 2.74 7.5 3.23 7.5 3.75C7.5 4.26 7.4 4.75 7.2 5.21C7 5.66 6.74 6.06 6.4 6.4C6.06 6.74 5.66 7 5.21 7.2C4.75 7.4 4.26 7.5 3.75 7.5ZM3.75 6.75C4.58 6.75 5.29 6.45 5.87 5.87C6.46 5.29 6.75 4.58 6.75 3.75C6.75 2.91 6.46 2.2 5.87 1.62C5.29 1.04 4.58 0.75 3.75 0.75C2.91 0.75 2.2 1.04 1.62 1.62C1.03 2.2 0.75 2.91 0.75 3.75C0.75 4.58 1.03 5.29 1.62 5.87C2.2 6.45 2.91 6.75 3.75 6.75Z" fill="#FFFFFF" fillOpacity="1.000000" fillRule="nonzero" />
                </svg>

                <div className="w-1/2">
                  {/* <FirstIcon /> */}
                </div>
                <p>{card.nft?.rating}</p>
              </div>
            </div>
          </div>
          {/* <div className='bg-red'> */}
          <div className="relative">
            {/* card?.nft?.image?.image */}
            {rarity === 'advanced' && (
              <div className={`rounded-block-css ${secondBG}`}>
                <img
                  className="block-image-card"
                  src={mockCard}
                  alt="player"
                  loading="lazy"
                />
              </div>
            )}
            {!(rarity === 'advanced') && (
              <img
                className="absolute bottom-0 left-0 z-[1] w-full h-full max-w-full object-contain pointer-events-none before:block before"
                src={card?.nft?.image?.image}
                alt="player"
                loading="lazy"
              />
            )}
            {/* <img
              className="m-[0_auto] h-full object-cover"
              src={imagePreview}
              alt="player_bg"
              loading="lazy"
            /> */}
            {/* </div> */}
          </div>
          <div className="relative grid grid-rows-[25%_65%_10%] m-0">
            <div className="relative text-center mt-[1.5rem]">
              <h3
                className={`absolute uppercase z-[5] font-bold text-[.8em] left-1/2 transform -translate-x-1/2 w-fit
                    ${
    rarity === 'advanced'
                      && '[text-shadow:-1px_0_0_#000] -top-[.6em]'
    }
                    ${rarity === 'unique' && 'text-black'}
                    ${rarity === 'common' && 'text-black'}
                  `}
              >
                {card?.nft?.name}
              </h3>
            </div>
            <div
              className={`flex justify-center gap-[1em] w-full font-bold text-[.5em] items-center mt-[2rem] ${
                rarity === 'common'
                  ? 'text-black'
                  : rarity === 'unique'
                    ? 'text-black'
                    : ''
              }`}
            >
              <p className="flex items-center gap-[.5em]">
                {/* <img className="w-[1.5em] h-[1em]" src={flag} alt="" /> */}
                {card?.nft?.player_country?.toUpperCase()}
              </p>
              <p className="">
                AGE:
                {card?.nft?.player_age}
              </p>
              <div className="whitespace-nowrap">
                {(card?.player_type === 'ОДИНОЧНАЯ МУЖСКАЯ' || card?.player_type === 'ПАРНАЯ МУЖСКАЯ') ? <IoMdMale className="inline" /> : <IoMdFemale className="inline" />}
                {' '}
                {(card?.player_type === 'ПАРНАЯ МУЖСКАЯ' || card?.player_type === 'ПАРНАЯ ЖЕНСКАЯ') ? 'PARE' : 'SOLO'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CharacteristicCard;
