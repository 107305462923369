import {
  memo, MutableRefObject, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { getFormatTime, TimeInfo } from '@utils/DateUtils';
import { StatusTournament, TournamentDetails } from '@services/tournaments.service';
import cls from './TournamentLiveTimer.module.scss';

function calculateTimeLeft(targetTime: Date): TimeInfo {
  const now = new Date();
  const difference = targetTime - now;
  let timeLeft: TimeInfo = {
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  }

  return timeLeft;
}

interface TournamentLiveTimerProps {
  tournament: TournamentDetails;
  dateEnd: Date;
  onRefetch: () => void;
  className?: string;
}

export const TournamentLiveTimer = memo((props: TournamentLiveTimerProps) => {
  const {
    dateEnd, tournament, onRefetch, className,
  } = props;
  const [timeInfo, setTimeInfo] = useState<TimeInfo>(calculateTimeLeft(dateEnd));
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const updateTimer = () => {
    setTimeInfo(calculateTimeLeft(dateEnd));
    timerRef.current = setTimeout(updateTimer, 1000);
  };

  useEffect(() => {
    updateTimer();

    return () => {
      if (timerRef.current) {
        console.log('callback');
        clearTimeout(timerRef.current);
      }
    };
  }, [dateEnd]);

  return (
    <div className={classNames(cls.TournamentLiveTimer, className)}>
      {tournament.status_tournament === 'НЕ НАЧАЛСЯ' ? (
        <h3 className={cls.waiting}>
          Waiting...
        </h3>
      ) : (
        <>
          <span className={cls.text}>
            {!tournament.is_break ? 'The round will end in' : 'The break will end in'}
            :
          </span>
          <h3 className={cls.time}>
            {getFormatTime(timeInfo)}
          </h3>
        </>
      )}
    </div>
  );
});
