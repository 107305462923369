import { memo } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import PlayersGroupIcon from '@assets/icons/players-group.svg';
import CardsIcon from '@assets/icons/cards.svg';
import cls from './RatingNavigation.module.scss';

interface RatingNavigation {
  className?: string;
}

export const RatingNavigation = memo((props: RatingNavigation) => {
  const { className } = props;
  const { pathname } = useLocation();

  return (
    <nav className={classNames(cls.RatingNavigation, className)}>
      <Link
        className={classNames(cls.link, pathname.includes('users') && cls.active)}
        to="/rating/users"
      >
        <PlayersGroupIcon className={cls.icon} />
        <span className={cls.text}>
          Players Rating
        </span>
      </Link>
      <Link
        className={classNames(cls.link, pathname.includes('cards') && cls.active)}
        to="/rating/cards"
      >
        <CardsIcon className={cls.icon} />
        <span className={cls.text}>
          Card Rating
        </span>
      </Link>
    </nav>
  );
});
