import { memo } from 'react';
import classNames from 'classnames';

import CalendarLeftArrowIcon from '@assets/icons/calendar/calendar-left-arrow.svg';
import CalendarRightArrowIcon from '@assets/icons/calendar/calendar-right-arrow.svg';
import { Button } from '@newComponents/shared/Button/Button';
import { getMonthName } from '@utils/DateUtils';
import cls from './TournamentsCalendarHeader.module.scss';

interface CalendarHeaderProps {
  dateInfo: Date;
  onIncrementMonth(): void;
  onDecrementMonth(): void;
  className?: string;
}

export const TournamentsCalendarHeader = memo((props: CalendarHeaderProps) => {
  const {
    dateInfo,
    onIncrementMonth,
    onDecrementMonth,
    className,
  } = props;

  return (
    <div className={classNames(cls.TournamentsCalendarHeader, className)}>
      <div className={cls.month}>
        {getMonthName(dateInfo.getMonth())}
        {' '}
        {dateInfo.getFullYear()}
      </div>
      <div className={cls.arrows}>
        <Button
          className={cls.arrow}
          theme="contained-grey"
          onClick={onDecrementMonth}
        >
          <CalendarLeftArrowIcon />
        </Button>
        <Button
          className={cls.arrow}
          theme="contained-grey"
          onClick={onIncrementMonth}
        >
          <CalendarRightArrowIcon />
        </Button>
      </div>
    </div>
  );
});
