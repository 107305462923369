import { UserModel } from '@models/User';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginByEmail, LoginByEmailResponse } from '@store/services/loginByEmail';

export interface AuthFields {
  username?: string;
  password?: string;
}

interface AuthSchema extends AuthFields {
  user?: UserModel;
  isLoading: boolean;
  validateErrors: AuthFields;
  authError: null | string,
}

const initialState: AuthSchema = {
  user: typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('user')),
  username: '',
  password: '',
  isLoading: false,
  validateErrors: {},
  authError: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
      state.validateErrors = {};
      state.authError = null;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
      state.validateErrors = {};
      state.authError = null;
    },
    logout: (state) => {
      state.user = null;
    },
    setValidateErrors: (state, action: PayloadAction<AuthFields>) => {
      console.log('action');
      state.validateErrors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginByEmail.pending.type, (state) => {
      state.isLoading = true;
    }),
    builder.addCase(loginByEmail.fulfilled.type, (state, action: PayloadAction<LoginByEmailResponse>) => {
      state.user = action.payload;
      state.isLoading = false;
    }),
    builder.addCase(loginByEmail.rejected.type, (state, action: PayloadAction<string>) => {
      console.log('error action');
      state.authError = action.payload;
      state.isLoading = false;
    });
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
