export const getItemShadowColor = (rarity: string) => {
  switch (rarity) {
  case 'ADVANCED':
    return 'advanced';
  case 'UNIQUE':
    return 'unique';
  case 'LEGENDARY':
    return 'legendary';
  case 'RARE':
    return 'rare';
  case 'COMMON':
    return 'common';
  default:
    return '';
  }
};
