import { memo, useEffect } from 'react';
import classNames from 'classnames';
import {
  useFetchTournamentResultsQuery,
} from '@services/tournaments.service';
import { useNavigate, useParams } from 'react-router-dom';
import { BACKEND_BASE_URL } from '@constants/constants';
import { Avatar } from '@newComponents/shared/Avatar/Avatar';
import cls from './TournamentGridResults.module.scss';
import { TournamentGridRound } from '../../grid/TournamentGridRound';
import { PageLoader } from '@newComponents/ui/PageLoader';

interface TournamentGridResultsProps {
  className?: string;
}

export const TournamentGridResults = memo((props: TournamentGridResultsProps) => {
  const { className } = props;
  const { tournamentId } = useParams<{tournamentId: string}>();
  const { data, isLoading } = useFetchTournamentResultsQuery(tournamentId);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (data && data.status_tournament === 'НЕ НАЧАЛСЯ') {
  //     navigate(`/tournaments/${tournamentId}`);
  //   }
  // }, [data, navigate, tournamentId])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <ul className={classNames(cls.TournamentGridResults, className)}>
      {data && data.round.map((round) => (
        <li className={cls.round} key={round.id}>
          <TournamentGridRound round={round} />
        </li>
      ))}
    </ul>
  );
});
