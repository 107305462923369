import { UserModel } from '@models/User';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from '@utils/api';

export interface LoginByEmailResponse extends UserModel {
  token: string;
  refresh_token: string;
}

export const loginByEmail = createAsyncThunk<LoginByEmailResponse, void>('auth/loginByEmail', async (_, thunkAPI) => {
  const { username } = thunkAPI.getState().auth;
  const { password } = thunkAPI.getState().auth;
  console.log(username, password);
  try {
    const { data } = await $api.post<LoginByEmailResponse>('/account/login/', {
      username, password,
    });
    localStorage.setItem('user', JSON.stringify(data));
    return data;
  } catch (error) {
    const errorData = error.response.data;
    if (errorData.non_field_errors?.length) {
      return thunkAPI.rejectWithValue('Неправильный E-mail или пароль');
    }
    return thunkAPI.rejectWithValue('Произошла ошибка при входе');
  }
});
