import { memo, useEffect } from 'react';
import classNames from 'classnames';
import Marquee from 'react-fast-marquee';
import ballImage from '@assets/images/ball.png';
import heroLineImage from '@assets/images/hero-line.png';
import futureImage from '@assets/images/future-image.png';
import mainCardImage from '@assets/images/main-work-card.png';
import mainMysteryImage from '@assets/images/main-mystery.png';
import videoBg from '@assets/videos/video-bg.mp4';
import cls from './Main.module.scss';
import { Button } from '../../../../newComponents/shared/Button/Button';
import { Container } from '../../../layout/Container';
import { Avatar } from '../../../../newComponents/shared/Avatar/Avatar';
import { Modal } from '../../../shared/Modal/Modal';
import { LoginModal } from '../../../ui/LoginModal';

interface MainProps {
  className?: string;
}

export const Main = memo((props: MainProps) => {
  const { className } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <main className={classNames(cls.Main, className)}>
      <section className={cls.hero}>
        <div className={cls.videoBackground}>
          <video autoPlay muted loop>
            <source src={videoBg} type="video/mp4" />
            Ваш браузер не поддерживает видео.
          </video>
        </div>
        <h2 className={cls.title}>
          NFT TENNIS
        </h2>
        <p className={cls.description}>
          NFT TENNIS is a virtual tennis emulator where users can trade, buy and sell players in NFT card format, compete with each other, participate in tournaments and win
        </p>
        <Button theme="contained-green" className={cls.heroButton}>
          Assemble a team
        </Button>
        <img className={cls.ball} src={ballImage} alt="" />
        <img className={cls.heroLine} src={heroLineImage} alt="" />
      </section>
      <section className={cls.future}>
        <h2 className={cls.futureTitle}>The future of cyber tennis</h2>
        <p className={cls.futureDescription}>Create unique teams and receive special prizes</p>
        <img className={cls.futureImage} src={futureImage} alt="future" />
        <Button className={cls.futureButton} theme="contained-green">Take part in the competition</Button>
      </section>
      <section className={cls.work}>
        <h2 className={cls.workTitle}>
          How does this work?
        </h2>
        <img className={cls.workImage} src={mainCardImage} alt="" />
        <Marquee speed={200} className={cls.marquee}>
          {new Array(16).fill('').map((_, index) => (
            <div key={index} className={cls.marqueeItem}>
              WIN TODAY
            </div>
          ))}
        </Marquee>
      </section>
      <section className={cls.functions}>
        <h2 className={cls.functionsTitle}>
          Key functions
        </h2>
        <ul className={cls.functionsList}>
          <li className={cls.functionsItem}>
            <div className={cls.functionsTextBlock}>
              <h3 className={cls.functionsItemTitle}>Ownership and rarity</h3>
              <p className={cls.functionsItemDescription}>
                Every tennis player is unique! Collect rare players and increase their value on the blockchain
              </p>
            </div>
          </li>
          <li className={cls.functionsItem}>
            <div className={cls.functionsTextBlock}>
              <h3 className={cls.functionsItemTitle}>Virtual Tournaments</h3>
              <p className={cls.functionsItemDescription}>
                Take part in tournaments with real rewards and show your skills
              </p>
            </div>
          </li>
          <li className={cls.functionsItem}>
            <div className={cls.functionsTextBlock}>
              <h3 className={cls.functionsItemTitle}>Awards and earnings </h3>
              <p className={cls.functionsItemDescription}>
                Win matches and earn rewards. Turn your NFTs into assets!
              </p>
            </div>
          </li>
        </ul>
        <Button className={cls.functionsButton} theme="contained-green">Assemble a team</Button>
      </section>
      <section className={cls.events}>
        <Container className={cls.eventsContainer}>
          <div className={cls.eventsContent}>
            <h2 className={cls.eventsTitle}>
              Upcoming events
            </h2>
            <p className={cls.eventsDescription}>
              Keep an eye on upcoming tournaments and matches so that you don't miss the chance to participate and win. Show the schedule
            </p>
            <Button className={cls.eventsButton} theme="contained-white">
              Show the schedule
            </Button>
          </div>
        </Container>
      </section>
      <section className={cls.mystery}>
        <h2 className={classNames(cls.mysteryTitle, cls.withLeftMargin)}>
          Открой свой первый
          {' '}
          <span className={classNames(cls.green)}>Mystery Box</span>
        </h2>
        <h2 className={classNames(cls.mysteryTitle, cls.secondTitle)}>
          Развивай свою команду, играй
          <br />
          <span className={cls.green}>и зарабатывай с нами!</span>
        </h2>
        <img
          className={cls.mysteryImage}
          src={mainMysteryImage}
          alt=""
        />
        <Button className={cls.mysteryButton} theme="contained-green">Assemble a team</Button>
      </section>
      <section className={cls.news}>
        <Container>
          <h2 className={cls.newsTitle}>
            NFT-Tennis News
          </h2>
          <ul className={cls.newsList}>
            <li className={cls.newsItem}>
              <div className={cls.newsItemAdditional}>
                <span className={cls.newsDate}>
                  May 20, 2024
                </span>
                <div className={cls.newsStats}>#competitions</div>
              </div>
              <div className={cls.newsItemMainContent}>
                <Avatar className={cls.newsAvatar} src={ballImage} size="large" />
                <div className={cls.newsItemTextBlock}>
                  <div>
                    <h3 className={classNames(cls.newsName, cls.green)}>
                      Каждый день
                    </h3>
                    <p className={classNames(cls.newsDescription, cls.green)}>
                      Participate in weekly tournaments to win valuable prizes every week
                    </p>
                  </div>
                  <a className={cls.newsLink} href="#">Read more</a>
                </div>
              </div>
            </li>
            <li className={cls.newsItem}>
              <div className={cls.newsItemAdditional}>
                <span className={cls.newsDate}>
                  May 20, 2024
                </span>
                <div className={cls.newsStats}>#competitions</div>
              </div>
              <div className={cls.newsItemMainContent}>
                <Avatar className={cls.newsAvatar} src={ballImage} size="large" />
                <div className={cls.newsItemTextBlock}>
                  <div>
                    <h3 className={classNames(cls.newsName, cls.green)}>
                      Каждый день
                    </h3>
                    <p className={classNames(cls.newsDescription, cls.green)}>
                      Participate in weekly tournaments to win valuable prizes every week
                    </p>
                  </div>
                  <a className={cls.newsLink} href="#">Read more</a>
                </div>
              </div>
            </li>
            <li className={cls.newsItem}>
              <div className={cls.newsItemAdditional}>
                <span className={cls.newsDate}>
                  May 20, 2024
                </span>
                <div className={cls.newsStats}>#competitions</div>
              </div>
              <div className={cls.newsItemMainContent}>
                <Avatar className={cls.newsAvatar} src={ballImage} size="large" />
                <div className={cls.newsItemTextBlock}>
                  <div>
                    <h3 className={classNames(cls.newsName, cls.green)}>
                      Каждый день
                    </h3>
                    <p className={classNames(cls.newsDescription, cls.green)}>
                      Participate in weekly tournaments to win valuable prizes every week
                    </p>
                  </div>
                  <a className={cls.newsLink} href="#">Read more</a>
                </div>
              </div>
            </li>
          </ul>
          <Button theme="contained-green" className={cls.newsViewButton}>View all</Button>
        </Container>
      </section>
      <img className={cls.footerLine} src={heroLineImage} alt="" />
    </main>
  );
});
