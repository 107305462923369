import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Container } from './Container';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const user = useSelector((state) => state.auth?.user);

  return (
    <>
      <Container>
        {user && <Sidebar />}
      </Container>
      <Header />
      {/* <Page> */}
      {children}
      {/* </Page> */}
    </>
  );
};
