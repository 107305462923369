import { memo } from 'react';
import starImage from '@assets/images/star.png';
import type { RatingUser } from '@services/rating.service';
import { BACKEND_BASE_URL } from '@constants/constants';
import classNames from 'classnames';
import { Avatar } from '../../../shared/Avatar/Avatar';
import cls from './RatingUserCard.module.scss';

interface RatingUserCardProps {
  item: RatingUser;
  topLevel?: number;
  className?: string;
}

export const RatingUserCard = memo((props: RatingUserCardProps) => {
  const { item, topLevel, className } = props;

  return (
    <div className={classNames(cls.RatingUserCard, className)}>
      {topLevel && (
        <div className={classNames(cls.rate, topLevel <= 3 && cls.gold)}>
          #
          {topLevel}
        </div>
      )}
      <div className={cls.userMainInfo}>
        <Avatar
          className={cls.avatar}
          size="medium"
          src={null}
        />
        <h3 className={cls.name}>
          {item.nickname}
        </h3>
      </div>
      <div className={cls.userStatsInfo}>
        <div className={cls.statsCountBlock}>
          <span className={cls.statsCountName}>
            Rating
          </span>
          <span className={cls.statsCount}>
            {item.experience}
          </span>
        </div>
        {/* <img className={cls.starImage} src={starImage} alt="level" /> */}
        <div className={cls.starBlock}>
          {item.level}
        </div>
      </div>
    </div>
  );
});
