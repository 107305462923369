import { Box, Html, OrbitControls } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';

import { TextureLoader } from 'three';
import './CardPage.scss';
import { useLocation, useParams } from 'react-router-dom';
import { BackCard, NewCharacteristicCard } from '@components/NewCharacteristicCard';
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import domtoimage from 'dom-to-image';
import axios from 'axios';
import { BACKEND_URL } from '@constants/constants';
import { AuthContext } from '@context/AuthContext';
import { PlayerCard } from '@components/PlayerCard';
import newPlayerCardFrontImage from './front.png';
import newPlayerCardBackImage from './back.png';

const getCardColor = (rarity: string) => {
  console.log('rarity', rarity);
  switch (rarity) {
  case 'ADVANCED':
    return 'rgb(229,178,77)';
  case 'UNIQUE':
    return 'rgb(229,178,77)';
  case 'LEGENDARY':
    return 'rgb(229,178,77)';
  case 'RARE':
    return 'rgb(229,178,77)';
  case 'COMMON':
    return 'rgb(229,178,77)';
  }
};

const Card = ({ frontTexture, backTexture, color }) => (
  <Box args={[3.8, 6, 0.25]} position={[0, 0.2, 0]} receiveShadow>
    <meshStandardMaterial color={color} roughness={0.2} metalness={0.8} emissive={color} />
    <mesh position={[0, 0, 0.13]} receiveShadow castShadow>
      <planeGeometry args={[3.8, 6]} />
      <meshStandardMaterial map={frontTexture} roughness={0.1} metalness={0.8} />
    </mesh>
    <mesh position={[0, 0, -0.13]} rotation={[0, Math.PI, 0]} receiveShadow castShadow>
      <planeGeometry args={[3.8, 6]} />
      <meshStandardMaterial map={backTexture} roughness={0.1} metalness={0.8} />
    </mesh>

    <Html position={[0, 0, 0.1]} transform style={{ position: 'absolute', top: '-105px', left: '-55px' }}>
      {/* Ваше содержимое HTML здесь */}
    </Html>
  </Box>
);

export const CardPage = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const id = 3546;

  const captureRef = useRef(null);
  const secondCaptureRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('https://sun9-69.userapi.com/impg/Xw4WYzjZvjcJJe3GluVyYT9Z4oAlAnJWcT0Wyw/5rozymOeeYA.jpg?size=200x324&quality=95&sign=f5c67d308c7b773d407c7fb832470d56&type=album');
  const [secondImageSrc, setSecondImageSrc] = useState('https://sun9-48.userapi.com/impg/UjwsKFmhLtqH2Up5PNQUqK3S6H5Qq_wrAusl4g/WxqUW1BhXeI.jpg?size=200x324&quality=95&sign=7c9ab58876f45f14d34bb4ba02cda231&type=album');

  const cardColor = useMemo(() => getCardColor('COMMON'), []);

  return (
    <div style={{ width: '60%', height: '70vh' }}>
      {imageSrc && secondImageSrc && cardColor && (
        <Canvas shadows style={{ overflow: 'visible' }}>
          <ambientLight intensity={13} />
          <directionalLight position={[10, 10, 10]} intensity={2} castShadow />
          <pointLight position={[0, 0, -5]} intensity={2} castShadow />
          <spotLight position={[5, 5, 5]} intensity={1.5} angle={0.2} penumbra={1} castShadow />
          <Card color={cardColor} frontTexture={useLoader(TextureLoader, newPlayerCardFrontImage)} backTexture={useLoader(TextureLoader, newPlayerCardBackImage)} />
          {location.pathname.includes('/profile/') ? <OrbitControls minDistance={4} maxDistance={4} /> : <OrbitControls minDistance={6} maxDistance={6} />}
        </Canvas>
      )}
    </div>
  );
};
