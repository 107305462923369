import { memo } from 'react';
import { useFetchCardsRatingsQuery } from '@services/rating.service';
import classNames from 'classnames';
import { PageLoader } from '@newComponents/ui/PageLoader';
import { RatingPlayerCard } from '../../RatingPlayerCard';
import cls from './RatingPlayersCardsList.module.scss';

interface RatingPlayersCardsListProps {
  className?: string;
}

export const RatingPlayersCardsList = memo((props: RatingPlayersCardsListProps) => {
  const { className } = props;
  const { data, isLoading } = useFetchCardsRatingsQuery();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <ul className={classNames(className)}>
      {data.map((item, index) => (
        <li className={cls.item}>
          <RatingPlayerCard
            key={item}
            item={item}
            topLevel={index + 1}
          />
        </li>
      ))}
    </ul>
  );
});
