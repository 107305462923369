import { memo } from 'react';
import classNames from 'classnames';
import cls from './NotificationsMenu.module.scss';

interface NotificationsMenuProps {
  className?: string;
}

export const NotificationsMenu = memo((props: NotificationsMenuProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.NotificationsMenu, className)}>
      <h3 className={cls.empty}>Список уведомлений пуст</h3>
    </div>
  );
});
