import { memo } from 'react';
import classNames from 'classnames';
import AuthService from '@services/AuthService';
import { BACKEND_BASE_URL } from '@constants/constants';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar } from '../../../../shared/Avatar/Avatar';
import cls from './HeaderProfileCard.module.scss';

interface HeaderProfileCardProps {
  className?: string;
}

export const HeaderProfileCard = memo((props: HeaderProfileCardProps) => {
  const { className } = props;
  const user = useSelector((state) => state.auth?.user);

  return (
    <Link className={classNames(cls.HeaderProfileCard, className)} to={`/profile/${user.id}`}>
      <h3 className={cls.name}>
        {user.nickname}
      </h3>
      <Avatar src={BACKEND_BASE_URL + user.avatar} alt="" />
    </Link>
  );
});
