import { memo, useCallback, useState } from 'react';
import SecondLogoIcon from '@assets/icons/second-logo.svg';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import cls from './HeaderNotAuthContent.module.scss';
import { Button } from '../../../../shared/Button/Button';
import { LoginModal } from '../../../../ui/LoginModal';

interface HeaderNotAuthContentProps {
  className?: string;
}

export const HeaderNotAuthContent = memo((props: HeaderNotAuthContentProps) => {
  const { className } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleIsOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <div className={classNames(cls.HeaderNotAuthContent, className)}>
      <Link to="/" className={cls.logoLink}>
        <div className={cls.logoBlock}>
          <SecondLogoIcon />
        </div>
      </Link>
      <div className={cls.buttons}>
        <Link className={cls.link} to="/send_code">
          <Button className={cls.button}>
            Register
          </Button>
        </Link>
        <Link to="#" onClick={handleIsOpen}>
          <Button className={cls.button} theme="outlined-white" onClick={handleIsOpen}>
            Sign in
          </Button>
        </Link>
      </div>
      <LoginModal isOpen={isOpen} onClose={handleClose} />
    </div>
  );
});
