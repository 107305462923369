import { MenuType } from '@newComponents/layout/Header/model/header';
import { useCallback, useState } from 'react';

export function useMenu<T>() {
  const [open, setOpen] = useState<boolean | MenuType>(false);

  const handleOpen = useCallback((value?: MenuType) => {
    setOpen(value || true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    handleOpen,
    handleClose,
  };
}
