import { memo } from 'react';
import classNames from 'classnames';
import cls from './TournamentLiveStats.module.scss';
import { Score } from '../../TournamentLiveInfo/ui/TournamentLiveInfo';

interface TournamentLiveStatsProps {
  score: Score;
  className?: string
}

export const TournamentLiveStats = memo((props: TournamentLiveStatsProps) => {
  const { score, className } = props;

  return (
    <table className={classNames(cls.TournamentLiveStats, className)}>
      <thead className={cls.thead}>
        <tr>
          <td className={cls.firstLine}>Очки гейма</td>
          <td>1-й гейм</td>
          <td>2-й гейм</td>
          <td>3-й гейм</td>
          <td>4-й гейм</td>
          <td>5-й гейм</td>
        </tr>
      </thead>
      <tbody className={cls.tbody}>
        <tr className={cls.firstLine}>
          <td>{score.player1?.[0]}</td>
          <td>{score.player1?.[1]}</td>
          <td>{score.player1?.[2]}</td>
          <td>{score.player1?.[3]}</td>
          <td>{score.player1?.[4]}</td>
          <td>{score.player1?.[5]}</td>
        </tr>
        <tr className={cls.secondLine}>
          <td>{score.player2?.[0]}</td>
          <td>{score.player2?.[1]}</td>
          <td>{score.player2?.[2]}</td>
          <td>{score.player2?.[3]}</td>
          <td>{score.player2?.[4]}</td>
          <td>{score.player2?.[5]}</td>
        </tr>
      </tbody>
    </table>
  );
});
