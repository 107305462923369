import { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { TournamentResultsRound } from '@services/tournaments.service';
import { Avatar } from '@newComponents/shared/Avatar/Avatar';
import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './TournamentGridRound.module.scss';
import { TournamentGridMatch } from '../../TournamentGridMatch';

interface TournamentGridRoundProps {
  round: TournamentResultsRound;
  className?: string;
}

export const TournamentGridRound = memo((props: TournamentGridRoundProps) => {
  const { round, className } = props;

  return (
    <div className={classNames(cls.TournamentGridRound, className)}>
      <h3 className={cls.title}>
        {round.name}
      </h3>
      <ul className={cls.matches}>
        {round.match.map((match) => (
          <TournamentGridMatch match={match} />
        ))}
      </ul>
    </div>
  );
});
