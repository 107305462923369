import SearchIcon from '@assets/icons/search.svg';
import BalanceIcon from '@assets/icons/balance.svg';
import NotificationsIcon from '@assets/icons/notifications.svg';

export type MenuType = 'balance' | 'notifications';

interface HeaderAuthItem {
  to?: string;
  Icon: string;
  menuType?: MenuType;
}

export const headerAuthItems: HeaderAuthItem[] = [
  {
    to: '/',
    Icon: SearchIcon,
  },
  {
    Icon: BalanceIcon,
    menuType: 'balance',
  },
  {
    Icon: NotificationsIcon,
    menuType: 'notifications',
  },
];
