import { memo, useEffect } from 'react';
import classNames from 'classnames';
import { BackButton } from '@newComponents/shared/BackButton/BackButton';
import { Page } from '../../../shared/Page/Page';
import cls from './Tournaments.module.scss';
import { TournamentsList } from '../../../ui/tournaments/TournamentsList';
import { TournamentAds } from '../../../ui/tournaments/TournamentAds';

interface TournamentsProps {
  className?: string;
}

export const Tournaments = memo((props: TournamentsProps) => {
  const { className } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page className={cls.Tournaments, className}>
      <BackButton className={cls.backButton} />
      <TournamentAds className={cls.ads} />
      <TournamentsList />
    </Page>
  );
});
