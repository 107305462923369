import { Page } from '@newComponents/shared/Page/Page';
import { CalendarHeader, TournamentsCalendarHeader } from '@newComponents/ui/tournaments/calendar/TournamentsCalendarHeader';
import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { TournamentsCalendarTable } from '@newComponents/ui/tournaments/calendar/TournamentsCalendarTable';
import { BackButton } from '@newComponents/shared/BackButton/BackButton';
import cls from './TournamentsCalendar.module.scss';

interface TournamentsCalendarProps {
  className?: string;
}

const newDate = new Date();

export const TournamentsCalendar = memo((props: TournamentsCalendarProps) => {
  const { className } = props;
  const [dateInfo, setDateInfo] = useState<Date>(newDate);

  const handleIncrementMonth = useCallback(() => {
    const year = dateInfo.getFullYear();
    const newMonth = dateInfo.getMonth() + 1;
    setDateInfo(new Date(year, newMonth));
  }, [dateInfo]);

  const handleDecrementMonth = useCallback(() => {
    const year = dateInfo.getFullYear();
    const newMonth = dateInfo.getMonth() - 1;
    setDateInfo(new Date(year, newMonth));
  }, [dateInfo]);

  return (
    <Page containerClassName={classNames(cls.TournamentsCalendar, className)}>
      <BackButton
        className={cls.backButton}
        text="Back to tournaments"
      />
      <TournamentsCalendarHeader
        className={cls.header}
        dateInfo={dateInfo}
        onIncrementMonth={handleIncrementMonth}
        onDecrementMonth={handleDecrementMonth}
      />
      <TournamentsCalendarTable dateInfo={dateInfo} />
    </Page>
  );
});
