import { Page } from '@newComponents/shared/Page/Page';
import { memo, useEffect } from 'react';
import classNames from 'classnames';
import { TournamentsInfoHeader } from '@newComponents/ui/tournaments/TournamentsInfoHeader';
import { TournamentsInfoMode } from '@newComponents/ui/tournaments/TournamentsInfoMode';
import { TournamentsNews } from '@newComponents/ui/tournaments/news/TournamentsNews';
import cls from './TournamentsInfo.module.scss';

interface TournamentsInfoProps {
  className?: string;
}

export const TournamentsInfo = memo((props: TournamentsInfoProps) => {
  const { className } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page className={classNames(cls.TournamentsInfo, className)}>
      <TournamentsInfoHeader className={cls.header} />
      <TournamentsInfoMode className={cls.mode} />
      <TournamentsNews />
    </Page>
  );
});
