import { Page } from "@newComponents/shared/Page/Page";
import { memo, useCallback, useEffect, useState } from "react";
import classNames from 'classnames'
import cls from './TournamentChangeTeam.module.scss';
import {
  useFetchTournamentTeamsQuery,
  useParticipateInTournamentMutation,
} from "@services/tournaments.service";
import { Team } from "@newComponents/ui/Team";
import { Button } from "@newComponents/shared/Button/Button";
import { BackButton } from "@newComponents/shared/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";

interface TournamentChangeTeamProps {
  className?: string;
}

export const TournamentChangeTeam = memo((props: TournamentChangeTeamProps) => {
  const { className } = props;
  const {data} = useFetchTournamentTeamsQuery();
  const [participateOnTournament] = useParticipateInTournamentMutation();
  const [selectedTeam, setSelectedTeam] = useState<number>();
  const {tournamentId} = useParams<{tournamentId: string}>();
  const navigate = useNavigate();

  const handleSelectTeam = useCallback((teamId: number) => {
    setSelectedTeam(teamId);
  }, [])

  const handleParticipateInTournament = useCallback(async () => {
    try {
      console.log(selectedTeam)
      await participateOnTournament({tournamentId, teamId: selectedTeam}).unwrap();
      navigate(`/tournaments/${tournamentId}`);
    } catch (error) {
      console.log(error)
    }
  }, [navigate, participateOnTournament, tournamentId, selectedTeam])

  useEffect(() => {
     window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (data && data.length === 1) {
      setSelectedTeam(data[0].id);
    }
  }, [data])

  return (
    <Page containerClassName={classNames(cls.TournamentChangeTeam, className)}>
      <BackButton
        className={cls.backButton}
        text="Back to tournaments list"
        to="/tournaments"
      />
      <h3 className={cls.title}>Choose a free team</h3>
      {data && (
        <>
          <ul className={cls.list}>
            {data.map((team) => (
              <li className={cls.item}>
                <Team
                  key={team.id}
                  team={team}
                  select={{
                    isSelected: selectedTeam === team.id,
                    onSelectTeam: handleSelectTeam
                  }}
                  />
              </li>
            ))}
          </ul>
          <Button
            className={cls.button}
            theme="contained-green"
            disabled={!Boolean(selectedTeam)}
            onClick={handleParticipateInTournament}
          >
            Participate
          </Button>
        </>
      )}
    </Page>
  )
})