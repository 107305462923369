import {
  memo, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import boxShadowImage from '@assets/images/players/shadow.png';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

import firstMockPlayerImage from '@assets/images/players/first-mock-player.png';
import secondMockPlayerImage from '@assets/images/players/second-mock-player.png';
import thirdMockPlayerImage from '@assets/images/players/third-mock-player.png';
import fourthMockPlayerImage from '@assets/images/players/fourth-mock-player.png';
import { Rarity } from '@services/cards.service';

import advancedLineImage from '@assets/images/players/advanced-line.png';
import rareLineImage from '@assets/images/players/rare-line.png';
import legendaryLineImage from '@assets/images/players/legendary-line.png';
import uniqueLineImage from '@assets/images/players/unique-line.png';
import commonLineImage from '@assets/images/players/common-line.png';
import { BACKEND_BASE_URL, BACKEND_URL } from '@constants/constants';
import axios from 'axios';
import AuthService from '@services/AuthService';
import StarIcon from '@assets/icons/star.svg';
import { CountryFlag } from '../../../shared/CountryFlag/CountryFlag';
import cls from './PlayerCard.module.scss';

interface PlayerCardProps {
  card: {
    id: string | number;
    name: string;
    circulation_number: number;
    rarity: 'ADVANCED' | 'RARE' | 'UNIQUE' | 'LEGENDARY' | 'COMMON';
    flag: string;
    player_country: string;
    player_age: number;
    rating: number
  }
  className?: string;
}

const mockPlayerImages = [
  firstMockPlayerImage,
  secondMockPlayerImage,
  thirdMockPlayerImage,
  fourthMockPlayerImage,
];

const getRandomMockImage = () => {
  const randomIndex = Math.floor(Math.random() * mockPlayerImages.length);
  return mockPlayerImages[randomIndex];
};

const getCardLine = (rarity: Rarity) => {
  switch (rarity) {
  case 'ADVANCED':
    return advancedLineImage;
  case 'LEGENDARY':
    return legendaryLineImage;
  case 'RARE':
    return rareLineImage;
  case 'COMMON':
    return commonLineImage;
  case 'UNIQUE':
    return uniqueLineImage;
  }
};

export const PlayerCard = memo((props: PlayerCardProps) => {
  const { card, className } = props;
  const [image, setImage] = useState<string | null>(null);
  const currentElementRef = useRef() as MutableRefObject<HTMLElement>;
  const userToken = AuthService.getCurrentUser()?.token;

  const takeScreenshot = async () => {
    const screenshotElement = currentElementRef.current;
    try {
      const canvas = await html2canvas(screenshotElement, {
        useCORS: true,
        allowTaint: true,
      });
      const imgData = canvas.toDataURL('image/jpeg');
      setImage(imgData);
      const base64Data = imgData.replace(/^data:image\/\w+;base64,/, '');
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: 'image/jpg' }); // Замените 'image/jpg' на нужный вам тип

      // Создаем FormData и добавляем Blob
      const formData = new FormData();
      formData.append('image_card', blob, 'image.jpg'); // Указываем имя файла
      formData.append('id', card.id.toString());
      // console.log(formData.get('file'), blob);

      await axios.post(`${BACKEND_URL}/nft/nft/add_image_card/`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    } catch (error) {
      console.error('Error taking screenshot:', error);
    }
  };

  useEffect(() => {
    // Вызываем скриншот только по запросу, например, по кноп```ке
    takeScreenshot(); // закомментируйте, если хотите делать по запросу
  }, []);

  const playerCountryCode = useMemo(() => {
    if (card.flag) {
      const cardFlagArray = card.flag.split('/');
      const cardFlagArrLength = cardFlagArray.length;
      return cardFlagArray[cardFlagArrLength - 1].slice(0, 2);
    }
  }, [card]);

  return (
    <div className={classNames(cls.PlayerCard)}>
      <img loading="lazy" className={classNames(cls.cardImage, className)} src={image} alt="" />
      {!image && (
        <div className={classNames(cls.card, card && cls[card.rarity.toLowerCase()])} ref={currentElementRef}>
          <img className={cls.cardLine} src={getCardLine(card.rarity)} alt="" />
          <div className={cls.rating}>
            <StarIcon className={cls.star} />
            <span className={cls.ratingCount}>
              {card.rating}
            </span>
          </div>
          <div className={cls.flag}>
            <span className={cls.flagName}>
              {card.player_country.slice(0, 2).toUpperCase()}
            </span>
            <CountryFlag
              className={cls.flagImage}
              countryCode={playerCountryCode}
            />
          </div>
          <div className={cls.age}>
            <span className={cls.infoBlockName}>
              AGE
            </span>
            <span className={cls.infoBlockText}>
              {card.player_age}
            </span>
          </div>
          <div className={cls.rank}>
            <span className={cls.infoBlockName}>
              RANK
            </span>
            <span className={cls.infoBlockText}>
              {card.rarity}
            </span>
          </div>
          <div className={cls.mainInfo}>
            <h3 className={cls.name}>{card.name}</h3>
            {/* <h2 className={cls.rate}>#{card.circulation_number}</h2> */}
          </div>
          <img className={cls.playerImage} src={getRandomMockImage()} alt="" />
          <img className={cls.boxShadow} src={boxShadowImage} alt="Shadow" />
        </div>
      )}
    </div>
  );
});
