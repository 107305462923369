import { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { getWeekDaysOfMonth } from '@utils/DateUtils';
import { useFetchTournamentsCalendarQuery } from '@services/tournaments.service';
import cls from './TournamentsCalendarTable.module.scss';
import { CalendarTournamentBadge } from '../../CalendarTournamentBadge';

interface TournamentsCalendarTableProps {
  dateInfo: Date;
  className?: string;
}

export const TournamentsCalendarTable = memo((props: TournamentsCalendarTableProps) => {
  const { dateInfo, className } = props;
  const { data } = useFetchTournamentsCalendarQuery();

  const weeks = useMemo(() => getWeekDaysOfMonth(
    dateInfo.getFullYear(),
    dateInfo.getMonth(),
  ), [dateInfo]);

  const isActiveDate = useCallback((day: number) => {
    const newDate = new Date();
    const currentYear = newDate.getFullYear();
    const currentMonth = newDate.getMonth();
    const currentDate = newDate.getDate();

    const dateInfoYear = dateInfo.getFullYear();
    const dateInfoMonth = dateInfo.getMonth();

    const isEqualYear = currentYear === dateInfoYear;
    const isEqualMonth = currentMonth === dateInfoMonth;
    const isEqualDate = currentDate === day;

    return isEqualYear && isEqualMonth && isEqualDate;
  }, [dateInfo]);

  const getTournamentsByDate = useCallback((date: number) => {
    console.log(date, dateInfo);
    return data?.filter((tournament) => {
      const tournamentStart = new Date(tournament.date_start);
      const tournamentYear = tournamentStart.getFullYear();
      const tournamentMonth = tournamentStart.getMonth();
      const tournamentDate = tournamentStart.getDate();

      const dateInfoYear = dateInfo.getFullYear();
      const dateInfoMonth = dateInfo.getMonth();

      const isEqualYear = tournamentYear === dateInfoYear;
      const isEqualMonth = tournamentMonth === dateInfoMonth;
      const isEqualDate = tournamentDate === date;

      const isEqual = isEqualYear && isEqualMonth && isEqualDate;

      return isEqual;
    });
  }, [data, dateInfo]);

  return (
    <div className={classNames(cls.TournamentsCalendarTable, className)}>
      <table className={cls.table}>
        <thead className={cls.thead}>
          <tr className={cls.line}>
            <td className={cls.column}>
              Monday
            </td>
            <td className={cls.column}>
              Tuesday
            </td>
            <td className={cls.column}>
              Wednesday
            </td>
            <td className={cls.column}>
              Thursday
            </td>
            <td className={cls.column}>
              Friday
            </td>
            <td className={cls.column}>
              Saturday
            </td>
            <td className={cls.column}>
              Sunday
            </td>
          </tr>
        </thead>
        <tbody className={cls.tbody}>
          {data && weeks.map((week) => (
            <tr key={week.toString()} className={cls.line}>
              {week.map((date) => (
                <td
                  key={date}
                  className={classNames(cls.bodyColumn, isActiveDate(date) && cls.active)}
                >
                  <span className={cls.date}>
                    {date}
                  </span>
                  {getTournamentsByDate(date)?.map((tournament) => (
                    <CalendarTournamentBadge
                      key={tournament.id}
                      className={cls.badge}
                      tournament={data?.[0]}
                    />
                  ))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
