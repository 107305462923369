import { Page } from '@newComponents/shared/Page/Page';
import { memo } from 'react';
import classNames from 'classnames';
import { useFetchNewsQuery } from '@services/news.service';

import newsMockImage from '@assets/images/news-mock-image.jpg';
import cls from './News.module.scss';

interface NewsProps {
  className?: string;
}

export const News = memo((props: NewsProps) => {
  const { className } = props;
  const { data } = useFetchNewsQuery();

  return (
    <Page containerClassName={classNames(cls.News, className)}>
      <div className={cls.content}>
        <span className={cls.date}>
          05.12.24
        </span>
        <h3 className={cls.title}>
          Australian Open. Касаткина, В. Кудерметова, Рыбакина,
          Швентек, Паолини вышли в 3-й круг, Э. Андреева выбыла
        </h3>
        <p className={classNames(cls.description, cls.withMargin)}>
          Fermentum ac mattis at arcu eleifend a leo faucibus pharetra. Vel bibendum nulla netus aliquet tellus. Elit volutpat tellus quis mi aliquet enim pretium proin venenatis. Ullamcorper lorem egestas eu lectus sagittis mi amet amet pharetra. Est elit pellentesque ut fringilla sem morbi.
        </p>
        <img
          className={classNames(cls.image, cls.withMargin)}
          src={newsMockImage}
          alt=""
        />
        <p className={classNames(cls.description)}>
          Fermentum ac mattis at arcu eleifend a leo faucibus pharetra. Vel bibendum nulla netus aliquet tellus. Elit volutpat tellus quis mi aliquet enim pretium proin venenatis. Ullamcorper lorem egestas eu lectus sagittis mi amet amet pharetra. Est elit pellentesque ut fringilla sem morbi.
        </p>
      </div>
      <ul className={cls.list}>
        <li className={cls.item}>
          <h4 className={cls.itemTitle}>
            Australian Open. Медведев отыграл матчбол и сравнял счет по сетам против Тьена, Хачанов, Синнер вышли в 3-й круг
          </h4>
          <span className={cls.itemDate}>
            8 минут назад
          </span>
        </li>
        <li className={cls.item}>
          <h4 className={cls.itemTitle}>
            Australian Open. Медведев отыграл матчбол и сравнял счет по сетам против Тьена, Хачанов, Синнер вышли в 3-й круг
          </h4>
          <span className={cls.itemDate}>
            8 минут назад
          </span>
        </li>
        <li className={cls.item}>
          <h4 className={cls.itemTitle}>
            Australian Open. Медведев отыграл матчбол и сравнял счет по сетам против Тьена, Хачанов, Синнер вышли в 3-й круг
          </h4>
          <span className={cls.itemDate}>
            8 минут назад
          </span>
        </li>
        <li className={cls.item}>
          <h4 className={cls.itemTitle}>
            Australian Open. Медведев отыграл матчбол и сравнял счет по сетам против Тьена, Хачанов, Синнер вышли в 3-й круг
          </h4>
          <span className={cls.itemDate}>
            8 минут назад
          </span>
        </li>
        <li className={cls.item}>
          <h4 className={cls.itemTitle}>
            Australian Open. Медведев отыграл матчбол и сравнял счет по сетам против Тьена, Хачанов, Синнер вышли в 3-й круг
          </h4>
          <span className={cls.itemDate}>
            8 минут назад
          </span>
        </li>
        <li className={cls.item}>
          <h4 className={cls.itemTitle}>
            Australian Open. Медведев отыграл матчбол и сравнял счет по сетам против Тьена, Хачанов, Синнер вышли в 3-й круг
          </h4>
          <span className={cls.itemDate}>
            8 минут назад
          </span>
        </li>
        <li className={cls.item}>
          <h4 className={cls.itemTitle}>
            Australian Open. Медведев отыграл матчбол и сравнял счет по сетам против Тьена, Хачанов, Синнер вышли в 3-й круг
          </h4>
          <span className={cls.itemDate}>
            8 минут назад
          </span>
        </li>
      </ul>
    </Page>
  );
});
