import { memo } from 'react';
import mockHeaderProfileImage from '@assets/images/mock-header-profile.jpg';
import classNames from 'classnames';
import cls from './ProfileHeader.module.scss';

interface ProfileHeaderProps {
  className?: string;
}

export const ProfileHeader = memo((props: ProfileHeaderProps) => {
  const { className } = props;

  return (
    <header className={classNames(cls.ProfileHeader, className)} />
  );
});
