import { memo, Suspense } from 'react';
import {
  Link, Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import classNames from 'classnames';
import { Profile } from '@services/profile.service';
import { Tabs } from '../../../../shared/Tabs/Tabs';
import { profileTabs } from '../model/profile';
import cls from './ProfileContent.module.scss';
import { EditProfileForm } from '../../EditProfileForm';
import { ProfileTransactions } from '../../ProfileTransactions';
import { ProfileTeams } from '../../ProfileTeams';

interface ProfileContentProps {
  user: Profile;
  className?: string;
}

export const ProfileContent = memo((props: ProfileContentProps) => {
  const { user, className } = props;
  const { pathname } = useLocation();

  return (
    <div className={classNames(cls.ProfileContent, className)}>
      <ul className={cls.tabs}>
        {profileTabs.map((item) => (
          <li
            className={classNames(cls.tabItem, pathname.includes(item.href.slice(1)) && cls.active)}
            key={`${item.name}_${item.href}`}
          >
            <Link className={cls.link} to={item.href}>
              {item.children}
            </Link>
          </li>
        ))}
      </ul>
      <Suspense fallback="">
        <Routes>
          <Route path="info" element={<ProfileTeams notViewLoading />} />
          <Route path="personal" element={<EditProfileForm user={user} />} />
          <Route path="transactions" element={<ProfileTransactions />} />
          <Route path="*" element={<Navigate replace to="./info" />} />
        </Routes>
      </Suspense>
    </div>
  );
});
