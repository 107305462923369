import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  to?: HTMLElement;
  children: ReactNode;
}

export const Portal: FC<PortalProps> = (props) => {
  if (typeof document !== 'undefined') {
    const { to = document.body, children } = props;
    return createPortal(children, to);
  }
};
