import { memo } from 'react';
import classNames from 'classnames';
import { Button } from '@newComponents/shared/Button/Button';
import { Link } from 'react-router-dom';
import cls from './TournamentsInfoMode.module.scss';

interface TournamentsInfoModeProps {
  className?: string;
}

export const TournamentsInfoMode = memo((props: TournamentsInfoModeProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.TournamentsInfoMode, className)}>
      <div className={cls.modeBlock}>
        <div className={cls.modeInfo}>
          <p className={cls.modeInfoDate}>
            The nearest: Oct. 9-14
          </p>
          <div className={cls.modeInfoTextBlock}>
            <h3 className={cls.modeTitle}>
              PRO League
            </h3>
            <p className={cls.modeDescription}>
              Main tournament of team
            </p>
          </div>
        </div>
        <Link className={cls.link} to="/tournaments/pro">
          <Button className={cls.button} theme="contained-grey">
            Join the game
          </Button>
        </Link>
      </div>

      <div className={cls.modeBlock}>
        <div className={classNames(cls.modeInfo, cls.modeInfoSecond)}>
          <div className={cls.modeInfoTextBlock}>
            <h3 className={cls.modeTitle}>
              Friendly match
            </h3>
            <p className={cls.modeDescription}>
              Compete with your friends on a daily basis
            </p>
          </div>
        </div>
        <Button className={cls.button} theme="contained-grey">
          Friendly match
        </Button>
      </div>
    </div>
  );
});
