import { memo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useMenu } from '@hooks/useMenu';
import { NotificationsMenu } from '@newComponents/ui/NotificationsMenu';
import { PageTitle } from '@newComponents/ui/PageTitle';
import { headerAuthItems, MenuType } from '../../model/header';
import cls from './HeaderAuthContent.module.scss';
import { HeaderProfileCard } from '../HeaderProfileCard/HeaderProfileCard';
import { Menu } from '../../../../shared/Menu/Menu';
import { Balance } from '../../../../ui/Balance';

interface HeaderAuthContentProps {
  className?: string;
}

export const HeaderAuthContent = memo((props: HeaderAuthContentProps) => {
  const { className } = props;
  const { open, handleOpen, handleClose } = useMenu<MenuType>();

  return (
    <div className={classNames(cls.HeaderAuthContent, className)}>
      <PageTitle />
      <div className={cls.control}>
        <ul className={cls.links}>
          {headerAuthItems.map((item, index) => (
            <li className={classNames(cls.link, index === 1 && cls.withMargin)}>
              <Link to={item.to} onClick={() => handleOpen(item.menuType)}>
                <item.Icon />
              </Link>
            </li>
          ))}
        </ul>
        <HeaderProfileCard />
        <Menu
          isOpen={open === 'balance'}
          onClose={handleClose}
          title="Баланс"
        >
          <Balance />
        </Menu>
        <Menu
          isOpen={open === 'notifications'}
          onClose={handleClose}
          title="Уведомления"
        >
          <NotificationsMenu />
        </Menu>
      </div>
    </div>
  );
});
