import { TabItem } from '../../../../shared/Tabs/Tabs';

export const profileTabs: TabItem[] = [
  {
    children: 'Information',
    href: './info',
  },
  {
    children: 'Personalization',
    href: './personal',
  },
  {
    children: 'Transactions',
    href: './transactions',
  },
];
