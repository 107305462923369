import { memo } from 'react';
import { useFetchUsersRatingsQuery } from '@services/rating.service';
import classNames from 'classnames';
import { PageLoader } from '@newComponents/ui/PageLoader';
import { RatingUserCard } from '../../RatingUserCard';
import cls from './RatingUsersList.module.scss';

interface RatingUsersListProps {
  className?: string;
}

export const RatingUsersList = memo((props: RatingUsersListProps) => {
  const { className } = props;
  const { data, isLoading } = useFetchUsersRatingsQuery();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <ul className={classNames(className)}>
      {data && data.map((item, index) => (
        <li key={item.id} className={cls.item}>
          <RatingUserCard item={item} topLevel={index + 1} />
        </li>
      ))}
    </ul>
  );
});
