import MainIcon from '@assets/icons/sidebar/main.svg';
import ShopIcon from '@assets/icons/sidebar/shop.svg';
import PlayIcon from '@assets/icons/sidebar/play.svg';
import RatingIcon from '@assets/icons/sidebar/rating.svg';
import MyClubIcon from '@assets/icons/sidebar/my-club.svg';
import GameIcon from '@assets/icons/sidebar/game.svg'

export interface SidebarItemType {
  Icon: string;
  name: string;
  to: string;
}

export const sidebarItems: SidebarItemType[] = [
  {
    name: 'Main',
    Icon: MainIcon,
    to: '/',
  },
  {
    name: 'Shop',
    Icon: ShopIcon,
    to: '/market/cards',
  },
  {
    name: 'Play',
    Icon: PlayIcon,
    to: '/tournaments',
  },
  {
    name: 'My Club',
    Icon: MyClubIcon,
    to: '/my-club/teams',
  },
  {
    name: 'Rating',
    Icon: RatingIcon,
    to: '/rating/users',
  },
  {
    name: 'Mini games',
    Icon: GameIcon,
    to: '/mini-games',
  },
];
