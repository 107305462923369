import { memo } from 'react';
import classNames from 'classnames';
import casePrizeVideo from '@assets/videos/case-prize.mp4';
import cls from './MysteryCasePrize.module.scss';

interface MysteryCasePrizeProps {
  onResetStatus: () => void;
  className?: string;
}

export const MysteryCasePrize = memo((props: MysteryCasePrizeProps) => {
  const { onResetStatus, className } = props;

  return (
    <div className={classNames(cls.MysteryCasePrize, className)}>
      <video className={cls.video} autoPlay onEnded={onResetStatus}>
        <source src={casePrizeVideo} type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>
    </div>
  );
});
