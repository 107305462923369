import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketFilter, marketFilters } from '../../newComponents/ui/market/MarketFilters/config/market';

interface MarketSchema {
  filters: {
    [key: string]: string;
  }
}

const initialState: MarketSchema = {
  filters: {},
};

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setUpdateFilters: (state, action: PayloadAction<object>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const {
  reducer: marketReducer,
  actions: marketActions,
} = marketSlice;
