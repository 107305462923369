import { memo } from 'react';
import classNames from 'classnames';
import { Link, Route, Routes } from 'react-router-dom';
import { GameOverlay } from '@components/GameOverlay/GameOverlay';
import FlappyBirdGame from '@screens/FlappyBirdGame/ui';
import { PingPongGame } from '@screens/PingPongGame';
import TetrisGame from '@components/TetrisGame/ui';
import { Button } from '../../../shared/Button/Button';
import { Page } from '../../../shared/Page/Page';
import cls from './MiniGames.module.scss';

interface MiniGamesProps {
  className?: string;
}

export const MiniGames = memo((props: MiniGamesProps) => {
  const { className } = props;
  return (
    <Page
      className={classNames(cls.MiniGames, className)}
      containerClassName={cls.miniGamesContainer}
      fullWidth
    >
      <div className={classNames(cls.miniGame, cls.firstGame)}>
        <div className={cls.content}>
          <h3 className={cls.title}>Impact force</h3>
          <Link className={cls.link} to="/mini-games/tetris">
            <Button className={cls.button} theme="opacity">
              Start a workout
            </Button>
          </Link>
        </div>
      </div>
      <div className={classNames(cls.miniGame, cls.secondGame)}>
        <div className={cls.content}>
          <h3 className={cls.title}>Speed</h3>
          <Link className={cls.link} to="/mini-games/game_flappy">
            <Button className={cls.button} theme="opacity">
              Start a workout
            </Button>
          </Link>
        </div>
      </div>
      <div className={classNames(cls.miniGame, cls.thirdGame)}>
        <div className={cls.content}>
          <h3 className={cls.title}>Impact accuracy</h3>
          <Link className={cls.link} to="/mini-games/tennis">
            <Button className={cls.button} theme="opacity">
              Start a workout
            </Button>
          </Link>
        </div>
      </div>

      <Routes>
        <Route
          path="/game_flappy"
          element={(
            <GameOverlay name="Игра Tennis Bird" notRotateForMobile>
              <FlappyBirdGame />
            </GameOverlay>
          )}
        />
        <Route
          path="/tetris"
          element={(
            <GameOverlay closeWithReload name="Игра Tetris" notRotateForMobile>
              <TetrisGame />
            </GameOverlay>
          )}
        />
        <Route
          path="/tennis"
          element={(
            <GameOverlay name="Игра Ping Pong">
              <PingPongGame />
            </GameOverlay>
          )}
        />
      </Routes>
    </Page>
  );
});
