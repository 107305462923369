import { Modal } from '@newComponents/shared/Modal/Modal';
import { memo } from 'react';
import classNames from 'classnames';
import { Button } from '@newComponents/shared/Button/Button';
import cls from './TournamentResultModal.module.scss';

export type TournamentResultType = 'looser' | 'winner';

interface TournamentResultModalProps {
  type?: TournamentResultType;
  onClose: () => void;
  className?: string;
}

export const TournamentResultModal = memo((props: TournamentResultModalProps) => {
  const { type, onClose, className } = props;

  return (
    <Modal
      contentClassName={classNames(cls.TournamentResultModal, className)}
      isOpen={Boolean(type)}
      onClose={onClose}
    >
      <h3 className={cls.title}>
        {type === 'looser' ? "You've lost" : "You've won"}
      </h3>
      <p className={cls.description}>
        Champions League: NFT Tennis Masters
      </p>
      <Button
        className={cls.button}
        theme={type === 'looser' ? 'contained_red' : 'contained-green'}
        onClick={onClose}
      >
        Go to the tournament grid
      </Button>
    </Modal>
  );
});
