import { memo } from 'react';
import RussiaIcon from '@assets/icons/ru.svg';
import BraziliaIcon from '@assets/icons/br.svg';
import USAIcon from '@assets/icons/us.svg';

interface CountryFlagProps {
  countryCode: 'ru' | 'br' | 'us';
  className?: string;
}

const getCountryIcon = (country: string) => {
  switch (country) {
  case 'ru':
    return RussiaIcon;
  case 'br':
    return BraziliaIcon;
  case 'us':
    return USAIcon;
  }
};

export const CountryFlag = memo((props: CountryFlagProps) => {
  const { countryCode, className, ...rest } = props;
  const CountryIcon = getCountryIcon(countryCode);
  if (CountryIcon) {
    return (
      <CountryIcon className={className} {...rest} />
    );
  }
});
