import { memo } from 'react';
import classNames from 'classnames';
import tennisBallImage from '@assets/images/tennis-ball.png';
import cls from './PageLoader.module.scss';

interface PageLoaderProps {
  className?: string;
}

export const PageLoader = memo((props: PageLoaderProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.PageLoader, className)}>
      <img
        className={cls.image}
        src={tennisBallImage}
        alt="loading..."
      />
    </div>
  );
});
