import { memo } from 'react';
import classNames from 'classnames';

import mockTournamentImage from '@assets/images/mock-tournament.jpg';

import prizeFirstImage from '@assets/images/prize-first.png';
import prizeSecondImage from '@assets/images/prize-second.png';
import prizeThirdImage from '@assets/images/prize-third.png';
import prizeFourthImage from '@assets/images/prize-fourth.png';
import starImage from '@assets/images/star.png';
import cls from './TournamentPrizePool.module.scss';

interface TournamentPrizePoolProps {
  className?: string;
}

export const TournamentPrizePool = memo((props: TournamentPrizePoolProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.TournamentPrizePool, className)}>
      <h3 className={cls.title}>
        Prize pool
      </h3>
      <ul className={classNames(cls.list)}>
        <li className={cls.prizeItem}>
          <div className={cls.prizeItemPlace}>
            <img className={cls.prizeIcon} src={prizeFirstImage} alt="" />
            <span className={cls.prizeName}>1'st place</span>
          </div>
          <div className={cls.prizeItemScore}>
            <span className={cls.prizeItemMoney}>
              +$350
            </span>
            <div className={cls.prizeItemStarsBlock}>
              <img className={cls.prizeItemStarImage} src={starImage} alt="" />
              <span className={cls.prizeItemStars}>
                1200
              </span>
            </div>
          </div>
        </li>
        <li className={cls.prizeItem}>
          <div className={cls.prizeItemPlace}>
            <img className={cls.prizeIcon} src={prizeSecondImage} alt="" />
            <span className={cls.prizeName}>2'st place</span>
          </div>
          <div className={cls.prizeItemScore}>
            <span className={cls.prizeItemMoney}>
              +$350
            </span>
            <div className={cls.prizeItemStarsBlock}>
              <img className={cls.prizeItemStarImage} src={starImage} alt="" />
              <span className={cls.prizeItemStars}>
                1200
              </span>
            </div>
          </div>
        </li>
        <li className={cls.prizeItem}>
          <div className={cls.prizeItemPlace}>
            <img className={cls.prizeIcon} src={prizeThirdImage} alt="" />
            <span className={cls.prizeName}>3'st place</span>
          </div>
          <div className={cls.prizeItemScore}>
            <span className={cls.prizeItemMoney}>
              +$350
            </span>
            <div className={cls.prizeItemStarsBlock}>
              <img className={cls.prizeItemStarImage} src={starImage} alt="" />
              <span className={cls.prizeItemStars}>
                1200
              </span>
            </div>
          </div>
        </li>
        <li className={cls.prizeItem}>
          <div className={cls.prizeItemPlace}>
            <img className={classNames(cls.prizeIcon, cls.fourthPrizeIcon)} src={prizeFourthImage} alt="" />
            <span className={cls.prizeName}>4'st place</span>
          </div>
          <div className={cls.prizeItemScore}>
            <span className={cls.prizeItemMoney}>
              +$350
            </span>
            <div className={cls.prizeItemStarsBlock}>
              <img className={cls.prizeItemStarImage} src={starImage} alt="" />
              <span className={cls.prizeItemStars}>
                1200
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
});
