import { memo, useCallback } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import CopyIcon from '@assets/icons/copy.svg';
import cls from './ReferalShareBlock.module.scss';
import { Button } from '../../../../shared/Button/Button';

interface ReferalShareBlockProps {
  className?: string;
}

export const ReferalShareBlock = memo((props: ReferalShareBlockProps) => {
  const { className } = props;
  const user = useSelector((state) => state.auth.user);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(`https://nft-tennis.com?ref=${user.id}`);
  }, [user]);

  return (
    <div className={classNames(cls.ReferalShareBlock, className)}>
      <h3 className={cls.title}>
        Earn with your friends together!
      </h3>
      <p className={cls.description}>
        Invite your friends and get 5% of their deals.
      </p>
      <div className={cls.share}>
        <div className={cls.linkBlock}>
          <a className={cls.shareLink}>
            https://nft-tennis.com?ref=
            {user.id}
          </a>
          <CopyIcon className={cls.copyIcon} onClick={handleCopyLink} />
        </div>
        <Button className={cls.shareButton} theme="contained-green" onClick={handleCopyLink}>To share</Button>
      </div>
    </div>
  );
});
