import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';
import { Profile } from './profile.service';

export interface Tournament {
  id: number;
  category: string;
  cover: string;
  participants: number;
  place: string;
  name: string;
  status_tournament: string;
  image: null | string;
}

interface TournamentsResponse {
  count: number;
  next: null | string;
  page: number;
  page_count: number;
  previous: null | string;
  results: Tournament[];
}

export interface TournamentCalendarItem {
  id: number;
  image: string | null;
  place: string;
  category: string;
  date_start: string | Date
}

export type StatusTournament = 'НЕ НАЧАЛСЯ' | 'ИДЕТ' | 'ЗАКОНЧИЛСЯ'

export interface TournamentDetails {
    'id': number,
    'name': string,
    'image': null | string,
    'date_start': string,
    'duration_break': number,
    'duration_round': number,
    'place': string,
    'flag': string | null,
    current_round_end: string;
    'cover': string,
    'category': string,
    'participants': number,
    'price': number,
    is_break: boolean
    'description': string,
    'level': number,
    'status_tournament': StatusTournament,
    'winner': {
      id: number
    },
    loosers: number[]
}

interface UserMatch {
  user: Pick<Profile, 'id' | 'nickname' | 'avatar'>
}

export interface TournamentResultsMatch {
  player1: UserMatch | null,
  player2: UserMatch | null,
  winner: UserMatch | null
}

export interface TournamentResultsRound {
  name: string;
  match: TournamentResultsMatch[]
}

export interface TeamType {
  id: number;
  name: string;
  players: string[];
  crew: string[] | number[],
  tournament: string[] | number[]
}

export interface TournamentResults {
  current_round_name: null | string;
  date_start: string;
  duration_break: number;
  duration_round: number;
  loosers: number[];
  name: string;
  status_tournament: StatusTournament
  round: TournamentResultsRound[];
}

export const tournamentsAPI = createApi({
  reducerPath: 'tournamentsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchTournaments: builder.query<Tournament[], number>({
      query: () => ({
        url: '/tournaments/',
        params: {
          page: 1,
          status: 'all'
        },
      }),
      transformResponse: (response: TournamentsResponse) => response.results,
    }),
    fetchTournamentsCalendar: builder.query<TournamentCalendarItem[], void>({
      query: () => ({
        url: '/tournaments/calendar/',
      }),
    }),
    fetchTournamentDetails: builder.query<TournamentDetails, string>({
      query: (tournamentId: string) => ({
        url: `/tournaments/tournament_detail/${tournamentId}/`,
      }),
    }),
    fetchTournamentResults: builder.query<TournamentResults, string>({
      query: (tournamentId) => ({
        url: `/tournaments/result_tournament/${tournamentId}/`,
      }),
    }),
    fetchTournamentTeams: builder.query<TeamType[], void>({
      query: () => ({
        url: '/nft/teams_for_tournament/'
      })
    }),
    participateInTournament: builder.mutation<void, {tournamentId: string, teamId: number}>({
      query: ({tournamentId, teamId}) => ({
        method: 'POST',
        url: `/tournaments/registration/${tournamentId}/${teamId}/`  
      })
    })
  }),
});

export const {
  useFetchTournamentsQuery,
  useFetchTournamentsCalendarQuery,
  useFetchTournamentDetailsQuery,
  useFetchTournamentResultsQuery,
  useFetchTournamentTeamsQuery,
  useParticipateInTournamentMutation
} = tournamentsAPI;
