import { Page } from '@newComponents/shared/Page/Page';
import { memo, useEffect } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { TournamentLiveInfo } from '@newComponents/ui/tournaments/tournamentPage/TournamentLiveInfo';
import { BackButton } from '@newComponents/shared/BackButton/BackButton';
import cls from './Tournament.module.scss';

interface TournamentProps {
  className?: string;
}

export const Tournament = memo((props: TournamentProps) => {
  const { className } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page containerClassName={classNames(cls.Tournament, className)}>
      <BackButton
        className={cls.backButton}
        to="/tournaments"
      />
      <TournamentLiveInfo />
    </Page>
  );
});
